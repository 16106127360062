<script setup lang="ts">
import IconCrystalBall from "~/assets/icons/crystal-ball-line.svg?component"

const { navigationState, isOnSearchPage } = useNavigation()
const { searchState, openSearchBar } = useSearch()
const { searchViewId, searchButton, navIgnoreMainViewGestures, searchResultsIntentPillId } = useGlobals()
const expanded = computed(() => searchState.value.isEditing)
const search = computed(() => navigationState.value.search)
const path = computed(() => searchState.value.mode === "path")

// Component has a search button if the search input is not open and we
// aren't on search results If the search is open we show an input. If
// we are viewing search results then we are on the show the search intent
// TODO: focus the cursor whenever the isEditing changes to true
// TODO: don't use a click handler here, use a gesture
</script>

<template>
  <nav-pill-view
    :id="searchViewId"
    class="search-pill"
    :class="{ expanded, path }"
  >
    <search-view v-if="searchState.isEditing" />
    <div
      v-else-if="isOnSearchPage() && search"
      :id="searchResultsIntentPillId"
      class="search-results-intent"
      @click="openSearchBar(search.intent())"
    >
      <div class="results-for">
        Results for
      </div>
      <nav-pill-button class="search-fragment-button">
        <search-intent :search />
      </nav-pill-button>
    </div>
    <nav-pill-button
      v-else
      :class="[searchButton, navIgnoreMainViewGestures]"
      class="search-button"
      @click="openSearchBar()"
    >
      <icon-crystal-ball class="h-4.5 w-4.5" />
      <div>Search</div>
    </nav-pill-button>
  </nav-pill-view>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.search-pill
  transition: flex-grow ease-out 0.04s
  display: block
  flex-grow: 0
  &.expanded
    flex-grow: 1
  &.path
    @apply bg-[#{$global-violet-accent}]

.search-results-intent
  @apply h-[#{$search-h-fragment-lg}] flex gap-1 items-center cursor-pointer

.results-for
  @apply pl-2 text-[#{$global-t-tertiary}] flex-none h-full flex items-center

.search-fragment-button
  @apply px-2 bg-[#{$pill-bg-button-hover}]

.search-button
  @apply pr-1.5
</style>

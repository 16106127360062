<script setup lang="ts">
import type { Property } from "~/classes/entity"
import { PropertySchema } from "~/classes/entity"
import type { PropertyColor, PropertySize } from "~/classes/generated/interface"

const props = defineProps<{
  file: InterfaceFile
  propertyId: Property
  size: PropertySize
  color: PropertyColor
  selected?: boolean
  diff?: InterfaceFile
  multiline?: boolean
  maxLines?: number
}>()
const { viewModePropertySizes, viewModePropertyColors } = useViewMode()
const { getPropertyAsDiff } = useProperty()
const property = computed(() => props.file.property(props.propertyId))
const propertyDiff = computed(() => props.diff ? getPropertyAsDiff(props.file, props.diff, props.propertyId) : undefined)
</script>

<template>
  <div v-if="propertyDiff" class="diff">
    <span
      v-for="diffItem, key in propertyDiff"
      :key
      :class="{
        deleted: diffItem[0] < 0,
        same: diffItem[0] === 0,
        added: diffItem[0] > 0,
      }"
    >
      {{ diffItem[1] }}
    </span>
  </div>
  <div
    v-else-if="property && property.value.type === PropertySchema.NonNullableStringValue"
    :data-property-id="propertyId"
    :style="multiline && maxLines ? multilineTextCss(maxLines) : {}"
    :class="{
      [viewModePropertySizes[size].textCss]: true,
      [viewModePropertyColors[color].textCss]: !selected,
    }"
  >
    {{ property.value.nonNullableString }}
  </div>
  <div
    v-else-if="property && property.value.type === PropertySchema.NullableStringValue"
    :data-property-id="propertyId"
    :style="multiline && maxLines ? multilineTextCss(maxLines) : {}"
    :class="{
      [viewModePropertySizes[size].textCss]: true,
      [viewModePropertyColors[color].textCss]: !selected,
    }"
  >
    {{ property.value.nullableString }}
  </div>
  <div v-else>
    Invalid component
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.diff
  @apply inline
  .deleted
    @apply bg-[#{$global-red-secondary}]
  .added
    @apply hidden
</style>

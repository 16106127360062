<script setup lang="ts">
defineProps<{ file: InterfaceFile }>()

const { moveToArchive, permanentlyDelete } = useContextMenu()
const { getArchiveForFile } = useFileSystem()
const { downloadFiles } = useTransferManager()
const { peekFiles } = usePeek()
const { addFilesToSidebarLast, removeFromSidebar, isFileInSidebar } = useSidebar()
</script>

<template>
  <div>
    <menu-section>
      <context-menu-dropdown-item
        v-if="!file.isFolder()"
        shortcut="␣"
        @click="peekFiles([file], 'main-view')"
      >
        Quick View
      </context-menu-dropdown-item>
      <context-menu-dropdown-item v-if="file.isFolder()" shortcut="↵">
        Open
      </context-menu-dropdown-item>
      <context-menu-dropdown-item v-else shortcut="↵">
        View
      </context-menu-dropdown-item>
      <context-menu-dropdown-item shortcut="⌘X">
        Cut
      </context-menu-dropdown-item>
      <context-menu-dropdown-item shortcut="⌘C">
        Copy
      </context-menu-dropdown-item>
      <context-menu-dropdown-item shortcut="⌘V">
        Paste
      </context-menu-dropdown-item>
      <menu-separator />
      <context-menu-dropdown-item @click="downloadFiles([file])">
        Download
      </context-menu-dropdown-item>
      <context-menu-dropdown-item
        v-if="isFileInSidebar(file)"
        @click="removeFromSidebar([file])"
      >
        Unpin from sidebar
      </context-menu-dropdown-item>
      <context-menu-dropdown-item
        v-else
        @click="addFilesToSidebarLast([file])"
      >
        Pin to sidebar
      </context-menu-dropdown-item>
    </menu-section>
    <menu-separator />
    <menu-section>
      <context-menu-dropdown-item
        v-if="getArchiveForFile(file)"
        @click="permanentlyDelete()"
      >
        <span class="delete-warning">Permanently Delete...</span>
      </context-menu-dropdown-item>
      <context-menu-dropdown-item
        v-else
        shortcut="⌫"
        @click="moveToArchive()"
      >
        Move to Archive
      </context-menu-dropdown-item>
    </menu-section>
    <menu-separator />
    <menu-section>
      <context-menu-dropdown-file-status :file />
    </menu-section>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.delete-warning
  @apply font-medium text-[#{$global-red-primary}]
</style>

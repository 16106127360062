// @ts-expect-error "color hash errors if we don't import the ESM version"
import ColorHash from "color-hash/dist/esm"

const colorHash = new ColorHash({ saturation: 1.0 })

export const stringToColor = (s: string): string => colorHash.hex(s)

export function generateColors(s: string): [string, string] {
  const s1 = s.substring(0, s.length / 2)
  const s2 = s.substring(s.length / 2)
  const c1 = stringToColor(s1)
  const c2 = stringToColor(s2)

  return [c1, c2]
}

export function gradientAvatar(s?: string, size = 256): string {
  if (!s)
    return ``
  const [c1, c2] = generateColors(s)

  return `
<svg width="${size}" height="${size}" viewBox="0 0 ${size} ${size}" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="${size / 2}" cy="${size / 2}" r="${
    size / 2
  }" fill="url(#gradient)" />
  <defs>
    <linearGradient id="gradient" x1="0" y1="0" x2="${size}" y2="${size}" gradientUnits="userSpaceOnUse">
      <stop stop-color="${c1}" />
      <stop offset="1" stop-color="${c2}" />
    </linearGradient>
  </defs>
</svg>
  `.trim()
}

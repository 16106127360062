<script setup lang="ts">
import type { StyleHTMLAttributes, StyleValue } from "vue"
import { Property, PropertySchema } from "~/classes/entity"
import type { PropertyColor, PropertySize } from "~/classes/generated/interface"
import { multilineTextCss } from "~/utils/helpers"

const props = defineProps<{
  file: InterfaceFile
  propertyId: Property
  size: PropertySize
  color: PropertyColor
  icon?: boolean
  textAlignment?: "center" | "left"
  selected?: boolean
  multiline?: boolean
  maxLines?: number
  /** Show a background to this property (e.g. in peek view) */
  bg?: boolean
  /** A different file whose property we diff this with */
  diff?: InterfaceFile
}>()

const { isEditingProperty } = useProperty()
const { viewModePropertySizes, viewModePropertyColors } = useViewMode()

const property = computed(() => props.file.property(props.propertyId))
const editing = computed(() => isEditingProperty(props.file.fileId, props.propertyId))
const center = computed(() => props.textAlignment === "center")
</script>

<template>
  <div
    class="property"
    :class="{
      editing,
      selected: selected && propertyId !== Property.VIRTUAL_TAGS,
      center,
      bg,
      [viewModePropertySizes[size].singleLineCss]: !multiline && !editing,
      [viewModePropertyColors[color].bgCss]: bg,
    }"
  >
    <!-- <div class="flex flex-none items-center">
      <component
        :is="fileIcons[propertyId as Property]"
        v-if="icon"
        class="flex-none pl-1 text-text-tertiary -mr-0.5"
      />
    </div> -->
    <file-property-editable-text
      v-if="propertyId === Property.NAME"
      :data-property-id="propertyId"
      :property-id
      :schema="PropertySchema.NonNullableStringValue"
      :selected
      :size
      :file
      :color
      :diff
    />
    <file-property-path
      v-else-if="propertyId === Property.VIRTUAL_PATH"
      :data-property-id="propertyId"
      :file
      :size
      :color
    />
    <file-property-dimensions
      v-else-if="propertyId === Property.VIRTUAL_DIMENSIONS && file.isImage()"
      :data-property-id="propertyId"
      :class="{
        [viewModePropertySizes[size].textCss]: true,
        [viewModePropertyColors[color].textCss]: !selected,
      }"
      :file="(file as InterfaceImageFile)"
      class="px-1.5 py-0.5"
    />
    <file-property-tags
      v-else-if="propertyId === Property.VIRTUAL_TAGS && file.tags().size"
      :file
      :size
    />
    <file-property-text
      v-else-if="property && [PropertySchema.NonNullableStringValue, PropertySchema.NullableStringValue].includes(property.value.type)"
      :file
      :property-id
      :size
      :color
      :selected
      :diff
      :multiline
      :max-lines
    />
    <div
      v-else-if="property && property.value.type === PropertySchema.UtcTimestampMillisValue"
      :data-property-id="propertyId"
      class="text-ellipses-inline default-property"
      :class="{
        [viewModePropertySizes[size].textCss]: true,
        [viewModePropertyColors[color].textCss]: !selected,
      }"
    >
      <file-property-time
        type="relative"
        :size
        :time="new Date(property.value.utcTimestampMillis)"
      />
    </div>
    <div
      v-else-if="property && property.value.type === PropertySchema.SizeBytesValue"
      :data-property-id="propertyId"
      class="text-ellipses-inline default-property"
      :class="{
        [viewModePropertySizes[size].textCss]: true,
        [viewModePropertyColors[color].textCss]: !selected,
      }"
    >
      {{ formatBytes(property.value.sizeBytes) }}
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.property
  @apply w-max-content max-w-full inline-flex rounded-[#{$page-properties-br-overlay}]
  &.editing
    @apply bg-[#{$global-blue-accent}]\!
  &.center
    @apply justify-center
  &:not(.editing)
    &.selected
      @apply bg-[#{$global-blue-primary}]\!
  &.bg
    @apply backdrop-filter backdrop-blur-[#{$menu-blur-default}]
.default-property
  @apply rounded-[#{$page-properties-br-overlay}] px-1.5 py-0.5
  // For some reason overflow wrap doesn't work here?
  word-break: break-word
</style>

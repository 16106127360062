<script setup lang="ts">
const { myself } = useUser()
const { logOut } = useAuthentication()
const { navigateToOnboarding } = useNavigation()
const { sidebarUserDropdownTestId } = useTestId()
const { sidebarState, showSidebar, hideSidebar } = useSidebar()

function openFeedback() {
  navigateTo(
    "mailto:hello@withpoly.com",
    { external: true, open: { target: "_blank" } },
  )
}
</script>

<template>
  <interface-dropdown position="bottom-left">
    <template #default="{ open }">
      <user-avatar
        :user="myself.user"
        class="user-avatar"
        :size="24"
        @click="open()"
      />
    </template>
    <template #menu>
      <div class="self-dropdown" :data-testid="sidebarUserDropdownTestId">
        <menu-section>
          <menu-header :label="myself.user?.email() || ''" />
          <context-menu-dropdown-item @click="logOut()">
            <div>Logout</div>
          </context-menu-dropdown-item>
        </menu-section>
        <menu-separator />
        <menu-section>
          <context-menu-dropdown-item v-if="sidebarState.visible" @click="hideSidebar()">
            <div>Hide Sidebar</div>
          </context-menu-dropdown-item>
          <context-menu-dropdown-item v-else @click="showSidebar()">
            <div>Show Sidebar</div>
          </context-menu-dropdown-item>
        </menu-section>
        <menu-separator />
        <menu-section>
          <context-menu-dropdown-item @click="navigateToOnboarding()">
            <div>Onboarding</div>
          </context-menu-dropdown-item>
          <context-menu-dropdown-item @click="openFeedback()">
            <div>Feedback</div>
          </context-menu-dropdown-item>
          <context-menu-dropdown-item @click="navigateTo('/join-discord')">
            <div>Discord</div>
          </context-menu-dropdown-item>
        </menu-section>
      </div>
    </template>
  </interface-dropdown>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.user-avatar
  @apply cursor-pointer

.self-dropdown
  @apply bg-[#{$menu-bg-default}]
  @apply border-[#{$menu-b-outline}]
  @apply rounded-lg backdrop-blur-[#{$menu-blur-default}] backdrop-filter  pointer-events-auto border shadow-menu z-overlay-layer flex flex-col
</style>

<script setup lang="ts">
import { Property, PropertySchema } from "~/classes/entity"

const props = defineProps<{ file: InterfaceImageFile }>()

function dimensions() {
  const wProp = props.file.property(Property.WIDTH)
  if (!wProp || wProp.value.type !== PropertySchema.PositiveIntegerValue)
    return ""
  const width = wProp.value.positiveInteger
  const hProp = props.file.property(Property.HEIGHT)
  if (!hProp || hProp.value.type !== PropertySchema.PositiveIntegerValue)
    return ""
  const height = wProp.value.positiveInteger
  return `${width} x ${height}`
}
</script>

<template>
  <span>
    {{ dimensions() }}
  </span>
</template>

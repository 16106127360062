<script setup lang="ts">
const { searchState } = useSearch()

const files = computed(() => searchState.value.suggestions.files ?? [])
const selected = computed(() => searchState.value.suggestions.selection)
</script>

<template>
  <menu-section>
    <menu-header label="Suggested locations" />
    <search-helper-item
      v-for="file, i in files"
      :key="file.fileId"
      :selected="selected === i"
    >
      <file-item :file class="file-inner" />
    </search-helper-item>
  </menu-section>
  <menu-separator />
  <menu-section>
    <search-helper-info>
      <div class="mr-auto navigate-action-section">
        <div>Open</div> <menu-shortcut>↵</menu-shortcut>
      </div>
      <div class="ml-auto navigate-action-section">
        <div>Add to path</div> <menu-shortcut>Tab</menu-shortcut>
      </div>
    </search-helper-info>
  </menu-section>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.file-inner
  @apply gap-[#{$menu-search-gap-item-left}]

.navigate-action-section
  @apply inline-flex gap-[6px]
</style>

<script setup lang="ts">
import { PropertySize } from "~/classes/generated/interface"

const { searchState, acceptSearchSuggestion } = useSearch()

const tags = computed(() => searchState.value.suggestions.tags ?? [])
const selected = computed(() => searchState.value.suggestions.selection)
</script>

<template>
  <menu-section>
    <template v-for="tag, i in tags" :key="tag">
      <menu-header v-if="i === 0 && searchState.completion.content === tags[0]" label="Add to search" />
      <menu-header v-if="i === (searchState.completion.content === tags[0] ? 1 : 0)" label="Suggested Tags" />
      <search-helper-item
        :selected="selected === i"
        @click="acceptSearchSuggestion('tag', i)"
      >
        <file-property-tag :value="tag" :size="PropertySize.Medium" />
      </search-helper-item>
    </template>
  </menu-section>
</template>

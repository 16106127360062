<script setup lang="ts">
import { Property, propertyName } from "~/classes/entity"

defineProps<{ files: InterfaceFile[] }>()
const { corePropertyDisplay, generativePropertyDisplay, panelState } = usePanel()
</script>

<template>
  <div class="details-view">
    <div v-if="files.length === 1" class="section">
      <file-thumbnail
        :file="files[0]"
        :container-width="panelState.width"
        :container-height="panelState.width"
      />
    </div>
    <div v-else-if="files.length <= 4" class="section">
      <div class="grid grid-cols-2 gap-1">
        <file-thumbnail
          v-for="file in files"
          :key="file.fileId"
          :file
          cropped
          :container-width="panelState.width / 2"
          :container-height="panelState.width / 2"
        />
      </div>
    </div>
    <div v-else-if="files.length <= 9" class="section">
      <div class="grid grid-cols-3 gap-1">
        <file-thumbnail
          v-for="file in files"
          :key="file.fileId"
          :file
          cropped
          :container-width="panelState.width / 3"
          :container-height="panelState.width / 3"
        />
      </div>
    </div>
    <div v-else class="section">
      <div class="grid grid-cols-4 gap-1">
        <file-thumbnail
          v-for="file in files.slice(0, 16)"
          :key="file.fileId"
          :file
          cropped
          :container-width="panelState.width / 4"
          :container-height="panelState.width / 4"
        />
      </div>
    </div>
    <div v-if="files.length > 1" class="section">
      <div class="header">
        Selection
      </div>
      <div class="view inline">
        <div class="label">
          Items
        </div>
        <div>
          {{ files.length }}
        </div>
      </div>
    </div>
    <div class="section">
      <div class="header">
        Properties
      </div>
      <template
        v-for="{ editable, monospace, inline, propertyId }, key in corePropertyDisplay"
        :key
      >
        <div
          v-if="any(files, f => f.hasProperty(propertyId))"
          class="view"
          :class="{ inline }"
        >
          <div class="label">
            {{ propertyName(propertyId) }}
          </div>
          <panel-details-property-multi-path
            v-if="propertyId === Property.VIRTUAL_PATH"
            :files
            class="value"
          />
          <panel-details-property-multi-dimensions
            v-else-if="propertyId === Property.VIRTUAL_DIMENSIONS"
            :files
            class="value"
          />
          <panel-details-property-editable-text
            v-else-if="editable && files.length === 1"
            :file="files[0]"
            :schema-type="files[0].property(propertyId)!.schema"
            :property-id="(propertyId as Property)"
            :data-property-id="propertyId"
            wrap
            class="value"
          />
          <panel-details-property-multi-text
            v-else
            :class="{ monospace }"
            :files
            :property-id
            class="value"
          />
        </div>
      </template>
    </div>
    <div class="section">
      <div class="header">
        Details
      </div>
      <!-- <div class="view-full">
        <div class="label">
          Tags
        </div>
        <div v-if="isEditingTags || fileTagProperties.length">
          Editing Tags
        </div>
        <div
          v-else
          class="button"
          @click="createAndEditFileNotesProperty(file)"
        >
          <icon-plus
            :size="16"
            class="-ml-0.5"
            :stroke-width="1.5"
          />
          <div>Add Notes</div>
        </div>
      </div> -->
      <div class="view-full">
        <div class="label">
          Notes
        </div>
        <!-- <panel-details-property-editable-text
          v-if="fileNotesProperty"
          :file
          :schema-type="PropertySchema.NullableStringValue"
          :property-id="Property.FILE_NOTES"
          :data-property-id="Property.FILE_NOTES"
          newlines
        />
        <div
          v-else
          class="button"
          @click="createAndEditFileNotesProperty(file)"
        >
          <icon-plus
            :size="16"
            class="-ml-0.5"
            :stroke-width="1.5"
          />
          <div>Add Notes</div>
        </div> -->
      </div>
    </div>
    <div v-if="any(files, f => f.hasGenerativeMetadata())" class="section">
      <div class="header">
        Generation Data
      </div>
      <template v-for="{ monospace, multiline, editable, inline }, propertyId in generativePropertyDisplay" :key="propertyId">
        <div
          v-if="any(files, f => f.hasProperty(propertyId))"
          class="view"
          :class="{ inline }"
        >
          <div class="label">
            {{ propertyName(propertyId) }}
          </div>
          <panel-details-property-editable-text
            v-if="editable && files.length === 1"
            :file="files[0]"
            :schema-type="files[0].property(propertyId)!.schema"
            :property-id="(propertyId as Property)"
            :multiline
            :data-property-id="propertyId"
            wrap
            class="value"
          />
          <panel-details-property-multi-text
            v-else
            :class="{ monospace }"
            :files
            :property-id
            class="value"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.section
  @apply p-[#{$panel-properties-p-default}] flex flex-col gap-[#{$panel-properties-gap-properties}]
  & + .section
    @apply border-t border-t-[#{$menu-b-separator}]
  .header
    @apply t-[#{$global-t-default}] t-menu-lg font-medium
  .label
    @apply flex-none font-normal text-[#{$global-t-tertiary}] text-menu-sm

  .view
    @apply flex flex-col gap-[#{$panel-properties-gap-properties}]
  .view.inline
    @apply flex-row justify-between
    .value
      @apply text-ellipses-inline t-panel-sm text-right

  .value
    @apply t-panel-sm
    &.multiline
      @apply whitespace-normal overflow-auto max-h-40 break-words
    &:not(.multiline)
      @apply text-ellipses-inline
    &.monospace
      @apply

  .button
    @apply px-2 py-1 rounded-md hover:bg-accent-neutral inline-flex text-text-tertiary hover:text-[#{$global-t-default}] items-center gap-1 cursor-pointer
</style>

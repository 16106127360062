<script setup lang="ts">
import { useScroll } from "@vueuse/core"
import { ViewModeType } from "~/classes/generated/interface"
import { InterfaceFile } from "~/classes/file/InterfaceFile"
import { InterfaceSearch } from "~/classes/search/InterfaceSearch"

const console = useLogger("MainView", theme.colors.green.hex)

const { mainViewState, clearMainViewState, addFileToFolderView, addFileToSearchView, addFolderToOpenFolders, removeFolderFromOpenFolders, setOpenFolders, openFolderInColumnView } = useMainView()
const { viewModeState, isHierarchical } = useViewMode()
const globals = useGlobals()
const { addInterfaceEventListener, removeInterfaceEventListener } = useEvents()
const { listMultipleFoldersWithViewModeProperties } = useFileSystem()
const { gestureState } = useGestures()
const { navigationState, navigateToSearch } = useNavigation()
const { mainViewTestId } = useTestId()
const { searchState, closeSearchBar, onSavedSearch, clearSearchEditor } = useSearch()
const mainView = ref<HTMLDivElement>()
const { x, y } = useScroll(mainView)

/** Navigation that clears the view and changes the path and view */
const currentRequestId = ref<string | undefined>()

// TODO: what happens when we want to do large multi-component drags?
// TODO: move this method inside the view mode so that it can have logic to guess the number of
// items needed to request to fill its view?
async function onNavigatedToFolder(folder: InterfaceFolderFile) {
  if (isHierarchical()) {
    const folderIds = Object.keys(mainViewState.value.openFolders)
    currentRequestId.value = await listMultipleFoldersWithViewModeProperties(folderIds, 50)
  }
  else // TODO: only list the amount of items you need to to fill the view
    currentRequestId.value = await listMultipleFoldersWithViewModeProperties([folder.fileId], 50)
}

/** Is called when the user navigates to a search that wasn't kicked off by the search bar */
async function onNavigatedToExistingSearch(search: InterfaceSearch) {
  const requestId = generateWorkerRequestId()
  searchState.value.latestRequestId = requestId
  onSavedSearch(search.searchId(), requestId)
}

async function onSwitchedViewMode() {
  // If we are no longer in a hierarchical view mode, we need to reduce the list of open folders
  // to only the current one
  // TODO: column vs tree edge cases
  const current = navigationState.value.file
  if (!current)
    return console.warn("No current file")
  if (!isHierarchical()) {
    console.log("Switching away from hierarchical view")
    for (const folderId of Object.keys(mainViewState.value.openFolders))
      if (folderId !== current.fileId)
        removeFolderFromOpenFolders(folderId)
  }

  // In column view we have to ensure that our column widths is enough and that
  // our view selects the right items. Basically, we take the most recently opened folder and
  // use that as the deepest level, with the nav state file as the root.
  if (enumEq(viewModeState.value.current.mode, ViewModeType.Column)) {
    // The last column to be opened is our current one
    const openFolders = mainViewState.value.openFolders
    if (!openFolders || len(openFolders) === 0)
      throw new Error("No open folders")
    const lastOpenFolder = mainViewState.value.contents[lastKeyInserted(openFolders)!] || current
    const path = lastOpenFolder.path()
    if (!path)
      throw new Error("Last opened folder has no path")
    const filePath = [...path.map(f => f.fileId), lastOpenFolder.fileId]
    const rootIndex = filePath.indexOf(current.fileId)
    if (rootIndex === -1)
      throw new Error("root is not in path")
    const foldersToOpen = filePath.slice(rootIndex)

    // Set the column widths to match open folders
    const columns = foldersToOpen.map((_, i) => viewModeState.value.current.columnView?.columns[i] || 240)
    viewModeState.value.current.columnView = { columns }

    const newFolders = setOpenFolders(foldersToOpen)
    console.log("Set to column view mode", foldersToOpen, "opening new folders", newFolders)
    if (len(newFolders))
      listMultipleFoldersWithViewModeProperties(newFolders, 50) // TODO: set page size
  }
}

async function onExpandedFolder(folder: InterfaceFolderFile) {
  // console.log("on expanded", folder)
  if (!mainViewState.value.openFolders)
    return console.error("No open folders, can't expand folder")
  if (enumEq(viewModeState.value.current.mode, ViewModeType.Column))
    openFolderInColumnView(folder)
  else
    addFolderToOpenFolders(folder)
  mainViewState.value.openFolders[folder.fileId] = true
  listMultipleFoldersWithViewModeProperties([folder.fileId], 50)
}

async function onShrunkFolder(folder: InterfaceFolderFile) {
  // console.log("on shrunk", folder)
  if (!mainViewState.value.openFolders)
    return console.error("No open folders, can't shrink folder")
  delete mainViewState.value.openFolders[folder.fileId]
  removeFolderFromOpenFolders(folder.fileId)
}

function onFolderListPageResponse(response: FolderListPageResponseDatagram) {
  if (response.error || !response.files)
    return console.warn("File list errored, can't update view")

  // console.log("response received", mainViewState.value.openFolders, response)

  if (response.requestId === currentRequestId.value) {
    // if we are receiving a response to a new request, we must clear our state
    currentRequestId.value = undefined
  }

  if (!mainViewState.value.openFolders)
    return console.error("Open folders must be defined for view mode")
  if (mainViewState.value.openFolders[response.folderId])
    for (const file of response.files)
      addFileToFolderView(InterfaceFile.from(file))
}

function onSearchResponse(response: SearchResponseDatagram) {
  // We want to potentially put this somewhere else since we also
  // want to be able to clear the view when we receive the first
  // page of responses.
  console.log("Main view received search response", response)

  if (response.requestId !== searchState.value.latestRequestId)
    return console.warn("Received results for different search", response, searchState.value.latestRequestId)

  closeSearchBar()

  if (response.error || !response.search || response.files === undefined)
    // TODO: where to put the info about responding to a search request
    return console.warn("Search errored or contained no elements", response)

  clearSearchEditor()
  const search = new InterfaceSearch(response.search)
  navigateToSearch(search)

  // When we receive search results, we need to clear the main view,
  // then navigate to the newly formed search id, which we display
  // in the URL as well to create a permalink
  clearMainViewState()
  const scores = dictByKey(search.sortedResults(), r => r.fileId)
  for (const file of response.files)
    addFileToSearchView(InterfaceFile.from(file), scores[file.fileId]?.score)
}

watch(() => viewModeState.value.current.mode, onSwitchedViewMode)
watch(() => viewModeState.value.current.mode, onSwitchedViewMode)
watch(x, x => gestureState.value.mainView.scrollX = x)
watch(y, y => gestureState.value.mainView.scrollY = y)

onMounted(() => {
  addInterfaceEventListener("afterNavigatedToFolder", onNavigatedToFolder)
  addInterfaceEventListener("afterNavigatedToExistingSearch", onNavigatedToExistingSearch)
  addInterfaceEventListener("afterExpandedFolder", onExpandedFolder)
  addInterfaceEventListener("afterShrunkFolder", onShrunkFolder)
  addInterfaceEventListener("folderListPageResponse", onFolderListPageResponse)
  addInterfaceEventListener("searchResponse", onSearchResponse)
  // If we switched view modes or loaded the page from scratch, we need
  // to call our callback manually here.
  if (navigationState.value.firstPageLoaded && navigationState.value.file)
    onNavigatedToFolder(navigationState.value.file as InterfaceFolderFile)
  if (navigationState.value.firstPageLoaded && navigationState.value.search)
    onNavigatedToExistingSearch(navigationState.value.search)
})

onUnmounted(() => {
  removeInterfaceEventListener("afterNavigatedToFolder", onNavigatedToFolder)
  removeInterfaceEventListener("afterNavigatedToExistingSearch", onNavigatedToExistingSearch)
  removeInterfaceEventListener("afterExpandedFolder", onExpandedFolder)
  removeInterfaceEventListener("afterShrunkFolder", onShrunkFolder)
  removeInterfaceEventListener("folderListPageResponse", onFolderListPageResponse)
  removeInterfaceEventListener("searchResponse", onSearchResponse)
})
</script>

<template>
  <div
    :id="globals.mainViewId"
    ref="mainView"
    class="main-view"
    :data-testid="mainViewTestId"
  >
    <main-icon-view v-if="viewModeState.current.mode === ViewModeType.Icon" />
    <main-grid v-else-if="viewModeState.current.mode === ViewModeType.Grid" />
    <main-feed v-else-if="viewModeState.current.mode === ViewModeType.Feed" />
    <main-detail v-else-if="viewModeState.current.mode === ViewModeType.Detail" />
    <main-tree-view v-else-if="viewModeState.current.mode === ViewModeType.Tree" />
    <main-column-view v-else-if="viewModeState.current.mode === ViewModeType.Column" />
    <div v-else>
      Something went wrong...
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.main-view
  @apply h-full w-full overflow-auto
</style>

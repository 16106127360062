<script setup lang="ts">
const { createNewFolderInCurrentView, uploadHere } = useContextMenu()
</script>

<template>
  <div>
    <menu-section>
      <context-menu-dropdown-item
        shortcut="⌘D"
        @click="createNewFolderInCurrentView()"
      >
        New Folder
      </context-menu-dropdown-item>
    </menu-section>
    <menu-separator />
    <menu-section>
      <context-menu-dropdown-item
        shortcut="⌘O"
        @click="uploadHere()"
      >
        Upload Here...
      </context-menu-dropdown-item>
    </menu-section>
  </div>
</template>

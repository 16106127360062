<script setup lang="ts">

</script>

<template>
  <div class="separator" />
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.separator
  @apply h-0 w-full border-b-0.75 border-b-[#{$menu-b-separator}]
</style>

<script setup lang="ts">
import homeIcon from "~/assets/icons/poly-home-icon-44.png?url"
import archiveIcon from "~/assets/icons/poly-archive-icon-44.png?url"
import unknown64 from "~/assets/icons/mime/unknown-64.png?url"

// The item view for a file is the common preview + name view used throughout our navigation
// elements, such as the sidebar, the path, and in dropdowns. It is simply a flex container
// with the elements inside, designed to be styled externally

const props = defineProps<{
  file: InterfaceFile
}>()
const { myself } = useUser()
const thumb = ref<HTMLImageElement>()
const home = computed(() => props.file.fileId === myself.value.home?.fileId)
const archive = computed(() => props.file.fileId === myself.value.archive?.fileId)

onMounted(async () => {
  if (home.value || archive.value || !props.file || props.file.isBlank())
    return
  if (!thumb.value)
    throw new Error("Image not mounted")
  try {
    const image = props.file as InterfaceImageFile
    const uri = image.icon64CropUri()
    if (!uri)
      throw new Error("No uri")
    return await loadImage(uri, thumb.value)
  }
  catch (error) {}
  try {
    const uri = props.file.clientUri()
    if (!uri)
      throw new Error("No uri")
    return await loadImage(uri, thumb.value)
  }
  catch (error) {}
  thumb.value.src = unknown64
})
</script>

<template>
  <div class="item">
    <template v-if="file.isBlank()">
      <img
        ref="thumb"
        :src="unknown64"
        class="h-full aspect-square"
      >
      <div class="name">
        Loading...
      </div>
    </template>
    <template v-else-if="home">
      <img
        ref="thumb"
        :src="homeIcon"
        class="h-full aspect-square p-0.5"
      >
      <div class="name">
        Home
      </div>
    </template>
    <template v-else-if="archive">
      <img
        ref="thumb"
        :src="archiveIcon"
        class="h-full aspect-square"
      >
      <div class="name">
        Archive
      </div>
    </template>
    <template v-else>
      <img
        ref="thumb"
        src=""
        class="h-full aspect-square rounded-sm "
      >
      <div class="name ">
        {{ file.name() }}
      </div>
    </template>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.item
  @apply flex flex-none items-center h-full
.name
  @apply text-ellipses-inline h-full
</style>

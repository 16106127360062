<script setup lang="ts">
import { translate } from "~/utils/helpers"

const { gestureState } = useGestures()
const dragged = ref<HTMLDivElement>()

const initialX = gestureState.value.mousePosition?.clientX || -1000
const initialY = gestureState.value.mousePosition?.clientY || -1000
const search = computed(() => gestureState.value.searchDragged)

function manuallyUpdateDragPosition(event: MouseEvent) {
  dragged.value!.style.transform = translate(event.clientX, event.clientY)
}

onMounted(() => {
  document.body.addEventListener("mousemove", manuallyUpdateDragPosition, true)
})
onUnmounted(() => {
  document.body.removeEventListener("mousemove", manuallyUpdateDragPosition, true)
})
</script>

<template>
  <div
    ref="dragged"
    class="dragged-search"
    :style="{ transform: translate(initialX, initialY) }"
  >
    <search-intent v-if="search" :search />
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.dragged-search
  @apply pointer-events-none absolute h-6 max-w-24 transform overflow-hidden z-drag-layer py-1 px-2 rounded-full bg-[#{$pill-bg-default}]
</style>

<script setup lang="ts">
import type { InterfaceSharedFolderInfo } from "~/classes/sharedFolder/InterfaceSharedFolderInfo"

const props = defineProps<{
  x: number
  y: number
}>()
const edgeGap = 16
const { contextMenuState } = useContextMenu()
const { contextMenuMainViewBackgroundTestId, mainViewFileContextMenuTestId } = useTestId()

const menu = ref<HTMLDivElement>()
const page = document.body.getBoundingClientRect()
const staticX = props.x
const staticY = props.y
const updatedX = computed(() => {
  const width = menu.value?.getBoundingClientRect().width || 0
  return clamp(staticX, edgeGap, page.width - width - edgeGap)
})
const updatedY = computed(() => {
  const height = menu.value?.getBoundingClientRect().height || 0
  return clamp(staticY, edgeGap, page.height - height - edgeGap)
})
</script>

<template>
  <div
    ref="menu"
    class="menu-dropdown-container dropdown"
    :style="{ left: px(updatedX), top: px(updatedY) }"
  >
    <context-menu-dropdown-target-file
      v-if="contextMenuState.type === 'main-view-file' && contextMenuState.target"
      :data-testid="mainViewFileContextMenuTestId"
      :file="(contextMenuState.target as InterfaceFile)"
    />
    <context-menu-dropdown-target-image-preview v-else-if="contextMenuState.type === 'image-preview'" />
    <context-menu-dropdown-target-file
      v-else-if="contextMenuState.type === 'sidebar-file' && contextMenuState.target"
      :file="(contextMenuState.target as InterfaceFile)"
    />
    <context-menu-dropdown-target-search
      v-else-if="contextMenuState.type === 'sidebar-search' && contextMenuState.target"
      :search="(contextMenuState.target as InterfaceSearch)"
    />
    <context-menu-dropdown-target-sidebar-shared-folder
      v-else-if="contextMenuState.type === 'sidebar-shared-folder'"
      :shared-folder-info="(contextMenuState.target as InterfaceSharedFolderInfo)"
    />
    <context-menu-dropdown-target-path-file
      v-else-if="contextMenuState.type === 'path-file'"
      :file="(contextMenuState.target as InterfaceFile)"
    />
    <context-menu-dropdown-target-search
      v-else-if="contextMenuState.type === 'search-bar' && contextMenuState.target"
      :search="(contextMenuState.target as InterfaceSearch)"
    />
    <context-menu-dropdown-target-main-view-background
      v-else
      :data-testid="contextMenuMainViewBackgroundTestId"
    />
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.dropdown
  @apply w-[#{$menu-context-w-default}]
  @apply fixed overflow-hidden
</style>

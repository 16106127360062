<script setup lang="ts">
const { navIgnoreMainViewGestures } = useGlobals()
const { notificationState } = useNotifications()
const { transferState } = useTransferManager()
const { tauriState } = useTauri()
const { sharedFolderState } = useSharedFolders()
const { searchState } = useSearch()
const { isOnSearchPage } = useNavigation()
const { sidebarState } = useSidebar()
const { isDemoUser } = useUser()

const isEditingSearch = computed(() => searchState.value.isEditing)

// TODO: decide whether we need the selection view, really?
</script>

<template>
  <div class="header">
    <nav-window-menu-mac v-if="tauriState.platform === 'macos' && !sidebarState.visible" class="island -mt-3 -ml-3" />
    <nav-user v-if="!sidebarState.visible" />
    <nav-sign-in v-if="isDemoUser()" />
    <nav-history v-if="isDesktop()" :class="[navIgnoreMainViewGestures]" />
    <nav-tray-view
      v-show="len(notificationState.alerts) !== 0 || len(transferState.jobs) !== 0"
      :class="[navIgnoreMainViewGestures]"
    />
    <div v-if="!isEditingSearch" class="flex shrink-10000 flex-basis-[400px] gap-3" />
    <div class="flex flex-grow justify-center gap-3">
      <nav-path-view
        v-if="!isEditingSearch && !isOnSearchPage()"
        class="island flex-basis-auto"
      />
      <nav-search-bar class="island" />
    </div>
    <div v-if="!isEditingSearch" class="flex shrink-10000 flex-basis-[400px] justify-end gap-3">
      <!-- <nav-selection
        v-if="!isSelectionEmpty()"
        class="island"
        :class="[navIgnoreMainViewGestures]"
        z="navigation-layer"
      /> -->
      <nav-sharing-pill-view
        v-if="sharedFolderState.current"
        class="island"
        :class="[navIgnoreMainViewGestures]"
      />
      <nav-view-mode-view
        class="island"
        :class="[navIgnoreMainViewGestures]"
      />
      <nav-window-menu-windows v-if="tauriState.platform === 'windows'" class="island -mt-3 -mr-3" />
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.header
  @apply pointer-events-none absolute top-0 w-full flex justify-between gap-[#{$nav-gap-pills}] p-[#{$nav-gap-pills}] pb-0
.island
  @apply pointer-events-auto z-navigation-layer
</style>

<script setup lang="ts">
import folder64 from "~/assets/images/files/folder/poly_folder@64.webp"

const props = defineProps<{
  file: InterfaceFile
}>()
const { mainViewState } = useMainView()
const { viewModeState } = useViewMode()
const { isSelected } = useSelection()
const selected = computed(() => isSelected(props.file.fileId))
const open = computed(() => mainViewState.value.openFolders?.[props.file.fileId])
</script>

<template>
  <div>
    <div
      class="tree-item"
      :class="{ selected, open }"
      :data-file-id="file.fileId"
    >
      <div class="tree-folder-chevron">
        <main-tree-chevron v-if="file.isFolder()" :open />
      </div>
      <div class="tree-item-container">
        <file-thumbnail
          :file
          class="tree-file-icon"
          cropped
          shadowed
          :container-width="24"
          :container-height="24"
        />
        <!-- <div class="tree-file-text">
          {{ file.name() }}
        </div> -->
        <div class="tree-properties">
          <file-property-view
            v-for="{ propertyId, size, color } in viewModeState.current.show.slice(1)"
            :key="propertyId"
            :property-id
            :size
            :file
            :color
            :selected
            text-alignment="left"
          />
        </div>
      </div>
    </div>
    <div v-if="open" class="tree-item-contents">
      <main-tree-list :parent-id="file.fileId" />
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.tree-item
  @apply flex items-center gap-[#{$page-tree-gap-items-md}] w-full
.tree-item-container
  @apply flex items-center gap-[#{$page-tree-gap-items-md}] max-w-full overflow-hidden cursor-pointer

.tree-folder-chevron
  @apply flex-none w-4 h-4 flex items-center justify-center
.tree-file-icon
  @apply w-6 h-6 rounded-sm flex-none
.tree-properties
  @apply flex items-center
.tree-file-text
  @apply break-words overflow-hidden whitespace-nowrap text-ellipsis

.tree-item-contents
  @apply ml-4
</style>

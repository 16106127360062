<script setup lang="ts">
const props = defineProps<{ search: InterfaceSearch }>()
const intent = computed(() => props.search.intent())
</script>

<template>
  <div class="search-intent" :data-search-id="search.searchId()">
    <search-fragment-view
      v-for="fragment, key of intent?.fragments"
      :key
      :fragment
    />
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.search-intent
  @apply flex items-center text-ellipses-inline gap-[#{$search-gap-fragment}]
</style>

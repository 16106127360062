<script setup lang="ts">
const props = defineProps<{
  file: InterfaceFile
}>()

function status(): "loading" | "current" | "stale" | "pending" {
  if (props.file.isBlank())
    return "loading"
  if (props.file.isStale())
    return "stale"
  if (props.file.isPending())
    return "pending"
  return "current"
}
</script>

<template>
  <div class="dropdown-item">
    <div class="dropdown-container">
      <template v-if="status() === 'current'">
        <div class="dropdown-icon">
          <icon-action size="xs" type="check" />
        </div>
        <span class="dropdown-text">
          Current
        </span>
      </template>
      <template v-else-if="status() === 'pending'">
        <div class="dropdown-icon">
          <icon-action size="xs" type="pending" />
        </div>
        <span class="dropdown-text">
          Pending
        </span>
      </template>
      <template v-else-if="status() === 'stale'">
        <div class="dropdown-icon">
          <icon-action size="xs" type="stale" />
        </div>
        <span class="dropdown-text">
          Stale
        </span>
      </template>
      <template v-else-if="status() === 'loading'">
        <div class="dropdown-icon">
          <icon-action size="xs" type="loading" />
        </div>
        <span class="dropdown-text">
          Loading
        </span>
      </template>
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.dropdown-item
  @apply h-[#{$menu-context-h-item}]
  @apply text-[#{$menu-t-item-default}]
  @apply w-full text-size-sm t-menu-sm flex
  .dropdown-container
    @apply m-[#{$menu-m-hover-outer}] p-[#{$menu-p-hover-inner}] gap-[#{$menu-context-gap-status}]
    @apply flex flex-grow items-center w-full
  .dropdown-text
    @apply h-[#{$menu-context-h-item}] text-[#{$global-t-tertiary}]
    @apply overflow-hidden break-words text-ellipsis whitespace-nowrap t-xs
  .dropdown-icon
    @apply text-[#{$global-t-quaternary}] w-[#{$global-w-icon-xs}] h-[#{$global-w-icon-xs}] flex items-center justify-center
</style>

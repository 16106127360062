<script setup lang="ts">
import detailsIcon from "~/assets/icons/pill/details.svg"
import historyIcon from "~/assets/icons/pill/history.svg"
import waterfallIcon from "~/assets/icons/pill/waterfall.svg"

const { togglePanel, panelState } = usePanel()
</script>

<template>
  <div class="panel-pill">
    <nav-pill-view>
      <nav-pill-button
        class="pill-button"
        :class="{ selected: panelState.visible && panelState.selected === 'waterfall' }"
        :selected="panelState.visible && panelState.selected === 'waterfall'"
        @click="togglePanel('waterfall')"
      >
        <waterfall-icon class="icon" />
      </nav-pill-button>
      <nav-pill-button
        class="pill-button"
        :class="{ selected: panelState.visible && panelState.selected === 'details' }"
        :selected="panelState.visible && panelState.selected === 'details'"
        @click="togglePanel('details')"
      >
        <details-icon class="icon" />
      </nav-pill-button>
      <nav-pill-button
        class="pill-button"
        :class="{ selected: panelState.visible && panelState.selected === 'history' }"
        :selected="panelState.visible && panelState.selected === 'history'"
        @click="togglePanel('history')"
      >
        <history-icon class="icon" />
      </nav-pill-button>
    </nav-pill-view>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.panel-pill
  @apply pointer-events-none absolute bottom-[#{$nav-gap-pills}] right-[#{$nav-gap-pills}]
  .icon
    @apply text-[#{$pill-t-default}] mx-0.75
  .pill-button:hover,.pill-button.selected
    .icon
      @apply text-[#{$pill-t-hovered}]
</style>

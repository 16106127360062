<script setup lang="ts">
defineProps<{
  position: "bottom-right" | "bottom-left" | "left-top"
}>()
const active = defineModel<boolean>("active")
const dropdown = ref<HTMLDivElement>()

function handleClickOutside(event: MouseEvent) {
  if (!event.target)
    return
  if (event.target === dropdown.value || dropdown.value?.contains(event.target as Node))
    return
  if (active.value)
    active.value = false
}

function open() {
  active.value = true
}
function close() {
  active.value = false
}
function toggle() {
  active.value = !active.value
}

onMounted(() => {
  document.body.addEventListener("mousedown", handleClickOutside)
})
onUnmounted(() => {
  document.body.removeEventListener("mousedown", handleClickOutside)
})
</script>

<template>
  <div
    ref="dropdown"
    class="relative inline-block text-left"
  >
    <div>
      <slot
        :open
        :toggle
        :close
        :active
      />
    </div>
    <transition
      enter-active-class="transition duration-60 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-60 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <div
        v-show="active"
        class="absolute"
        :class="[position]"
      >
        <slot
          name="menu"
          :active
          :open
          :toggle
          :close
        />
      </div>
    </transition>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.bottom-left
  @apply mt-3 top-[100%] left-0 origin-top-left
.bottom-right
  @apply mt-3 top-[100%] right-0 origin-top-right
.left-top
  @apply ml-3 left-[100%] top-0 origin-top-left
</style>

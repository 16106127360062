<script setup lang="ts">
// We have a few different kinds of properties dropdowns

// 1. Numerical
// 2. Date
// 3. Text

const { searchState } = useSearch()

const options = computed(() => searchState.value.suggestions.tags ?? [])
const selected = computed(() => searchState.value.suggestions.selection)
</script>

<template>
  <menu-section>
    <menu-header label="Properties" />
    <search-helper-item
      v-for="option, i in options"
      :key="option"
      :selected="selected === i"
    >
      {{ option }}
    </search-helper-item>
  </menu-section>
</template>

<script setup lang="ts">
const { sortedNotifications, clearNotifications, notificationState } = useNotifications()

// TODO: fade in and out peek view
</script>

<template>
  <div v-if="notificationState.tray.isPeek && sortedNotifications.length > 0" class="peek-container">
    <div
      v-for="key in range(Math.min(sortedNotifications.length - 1, 3))"
      :key
      class="card"
      @click="notificationState.tray.isPeek = false"
    />
    <template v-for="alert in [sortedNotifications[0]]" :key="alert.id">
      <nav-tray-alert
        v-if="alert.type === 'error-alert' || alert.type === 'success-alert'"
        class="peek-notification"
        :alert
      />
      <nav-tray-job-upload
        v-else-if="alert.type === 'upload'"
        class="peek-notification"
        :job="alert"
      />
      <nav-tray-job-download
        v-else-if="alert.type === 'download'"
        class="peek-notification"
        :job="alert"
      />
      <div v-else>
        Unknown message type
      </div>
    </template>
    <div v-if="sortedNotifications.length > 1" class="more">
      <div class="inner" @click="notificationState.tray.isPeek = false">
        {{ sortedNotifications.length - 1 }} More
      </div>
    </div>
  </div>
  <div v-else class="notification-container">
    <div class="header">
      <div class="activity">
        Activity
      </div>
      <div class="clear-all" @click="clearNotifications()">
        Clear all
      </div>
    </div>
    <div class="notifications">
      <template v-for="alert in sortedNotifications" :key="alert.id">
        <nav-tray-alert
          v-if="alert.type === 'error-alert' || alert.type === 'success-alert'"
          :alert
        />
        <nav-tray-job-upload
          v-else-if="alert.type === 'upload'"
          :job="alert"
        />
        <nav-tray-job-download
          v-else-if="alert.type === 'download'"
          :job="alert"
        />
        <div v-else>
          Unknown message type
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.peek-container
  @apply w-[#{$menu-tray-w-default}] pointer-events-auto z-overlay-layer flex-col-reverse flex justify-end bg-none bg-[#{$menu-bg-default}] rounded-[#{$menu-br-default}] backdrop-blur-[#{$menu-blur-default}] backdrop-filter relative
  .peek-notification
    @apply z-1 rounded-[#{$menu-br-default}] overflow-hidden border-[#{$menu-b-outline}] border-[#{$menu-b-outline}] border
  .card
    @apply h-4 -mt-3 rounded-b-[#{$menu-br-default}] border-[#{$menu-b-outline}] border border-t-none z-1 cursor-pointer
  .more
    @apply absolute bottom-0 flex justify-center w-full t-menu-2xs text-[#{$global-t-secondary}]
    .inner
      @apply bg-[#{$menu-b-outline}] rounded-full z-2 px-1 cursor-pointer
      box-shadow: 0px 0px 4px #2C2C2C

.notification-container
  @apply bg-[#{$menu-bg-default}] border-[#{$menu-b-outline}] w-[#{$menu-tray-w-default}] rounded-[#{$menu-br-default}] overflow-auto backdrop-blur-[#{$menu-blur-default}] backdrop-filter pointer-events-auto border shadow-menu z-overlay-layer
  max-height: calc(100vh - 350px)

  .header
    @apply text-xs flex items-center p-4 pb-2
    .activity
      @apply text-[#{$menu-t-header}]
    .clear-all
      @apply text-[#{$menu-t-header}]
      @apply hover:bg-[#{$pill-bg-button-hover}]
      @apply hover:text-[#{$global-t-default}]
      @apply ml-auto px-2 py-1 -mr-2 -my-1 rounded-md cursor-pointer
  .notifications
    @apply
</style>

<script setup lang="ts">
import { useTimeAgo } from "@vueuse/core"
import type { PropertySize } from "~/classes/generated/interface"

const props = defineProps<{
  time: Date
  type?: "relative"
  size: PropertySize
}>()
const { viewModePropertySizes } = useViewMode()
const timeAgo = useTimeAgo(props.time)
</script>

<template>
  <span class="time" :class="viewModePropertySizes[size]">
    {{ timeAgo }}
  </span>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.time
  @apply text-ellipses-inline rounded-[#{$page-properties-br-overlay}]
</style>

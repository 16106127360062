<script setup lang="ts">
const { sortedContents } = useMainView()
const { viewModeState, previewScale } = useViewMode()
const { isSelected } = useSelection()

function previewGridWidth() {
  // const scale = mainViewState.value.viewModePreviewProperties.scale
  const scale = previewScale()
  const width = 2 ** (5 + 6 * scale)
  return `repeat(auto-fill, minmax(${width * 0.8}px, 1fr)`
}
function previewWidth() {
  // const scale = mainViewState.value.viewModePreviewProperties.scale
  const scale = previewScale()
  const width = 2 ** (5 + 6 * scale)
  return width
}

function showDetailsOnHoverOnly(file: InterfaceFile) {
  if (isSelected(file.fileId))
    return false
  if (file.isBlank() || file.isFolder())
    return false
  return true
}
</script>

<template>
  <div class="spacer" />
  <div
    class="grid gap-0.5 p-0.5"
    :style="{ 'grid-template-columns': previewGridWidth() }"
  >
    <div
      v-for="file in sortedContents"
      :key="file.fileId"
      :data-file-id="file.fileId"
      class="group relative grow flex-basis-[300px] cursor-pointer"
      :style="{ 'max-width': px(previewWidth()) }"
    >
      <div class="grid-file" :class="{ selected: isSelected(file.fileId) }" />
      <file-thumbnail
        :file
        :selected="isSelected(file.fileId)"
        :container-width="previewWidth()"
        :container-height="previewWidth()"
        flat
        cropped
        class="aspect-square h-full w-full object-cover"
      />
      <div
        class="grid-file-properties"
        :class="{
          hidden: showDetailsOnHoverOnly(file),
          flex: !showDetailsOnHoverOnly(file),
        }"
      >
        <file-property-view
          v-for="{ propertyId, size, color } in viewModeState.current.show"
          :key="propertyId"
          :property-id
          bg
          :size
          :file
          :color
          :selected="isSelected(file.fileId)"
          text-alignment="left"
          class="grid-file-property"
        />
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.spacer
  @apply pointer-events-none w-full
  @apply h-[#{$nav-h-default}] mb-[#{$nav-gap-pills}]
.grid-file
  @apply pointer-events-none absolute border-$global-blue-primary rounded-[2px] -bottom-0.5 -left-0.5 -right-0.5 -top-0.5 z-gesture-layer group-hover:border-2
  &.selected
    @apply border-2
.grid-file-properties
  @apply absolute bottom-0 left-0 max-w-full gap-0.25 overflow-hidden p-1 items-center group-hover:flex
.grid-file-property
  @apply
</style>

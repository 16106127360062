<script setup lang="ts">
const { gestureLayers } = useGestures()
const { navigationState } = useNavigation()
</script>

<template>
  <div class="preview-container">
    <renderer-view
      v-if="navigationState.file"
      :file="navigationState.file"
      :gesture-layer="gestureLayers.mainView"
    />
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.preview-container
  @apply h-full w-full flex items-center justify-center overflow-hidden
</style>

import { Node, mergeAttributes } from "@tiptap/core"
import type { DOMOutputSpec, Node as ProseMirrorNode } from "@tiptap/pm/model"
import { PluginKey } from "@tiptap/pm/state"
import { VueNodeViewRenderer } from "@tiptap/vue-3"

import FolderRefComponent from "~/components/Search/Editor/FolderRef.vue"

export interface FolderRefOptions {
  HTMLAttributes: Record<string, any>
  renderText: (props: { options: FolderRefOptions, node: ProseMirrorNode }) => string
  renderHTML: (props: { options: FolderRefOptions, node: ProseMirrorNode }) => DOMOutputSpec
  // suggestion: Omit<SuggestionOptions, 'editor'>
}

export const FolderRefPluginKey = new PluginKey("folderRef")

export const FolderRef = Node.create<FolderRefOptions>({
  name: "folderRef",

  addOptions() {
    return {
      HTMLAttributes: {},
      renderText({ options: _, node }) {
        return `${node.attrs.name}`
      },
      renderHTML({ options: _, node }) {
        return [
          "span",
          this.HTMLAttributes,
          `${node.attrs.name}|${node.attrs.fileId}`,
        ]
      },
    }
  },
  group: "inline",
  inline: true,
  selectable: false,
  draggable: true,
  atom: true,
  addAttributes() {
    return {
      name: {
        default: "Loading...",
      },
      fileId: {
        default: null,
        parseHTML: element => element.getAttribute("file-id"),
        renderHTML: (attributes) => {
          if (!attributes.fileId)
            return {}

          return {
            "file-id": attributes.fileId,
          }
        },
      },
    }
  },

  addNodeView() {
    return VueNodeViewRenderer(FolderRefComponent)
  },

  parseHTML() {
    return [
      {
        tag: `folder-ref`,
      },
    ]
  },

  renderHTML({ node: _, HTMLAttributes }) {
    return [
      "folder-ref",
      mergeAttributes({}, this.options.HTMLAttributes, HTMLAttributes),
    ]
  },

  renderText({ node }) {
    return this.options.renderText({
      options: this.options,
      node,
    })
  },

  addKeyboardShortcuts() {
    return {
      Backspace: () => this.editor.commands.command(({ tr, state }) => {
        let isFolderRef = false
        const { selection } = state
        const { empty, anchor } = selection

        if (!empty)
          return false

        state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
          if (node.type.name === this.name) {
            isFolderRef = true
            // TODO: this retains the @ symbol when pressed backspace
            // tr.insertText(this.options.suggestion.char || '', pos, pos + node.nodeSize)
            tr.insertText(" ", pos, pos + node.nodeSize)

            return false
          }
        })

        return isFolderRef
      }),
    }
  },

  // addProseMirrorPlugins() {
  //   return [
  //     Suggestion({
  //       editor: this.editor,
  //       ...this.options.suggestion,
  //     }),
  //   ]
  // },
})

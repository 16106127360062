<script setup lang="ts">
import folderIcon from "~/assets/icons/folder-filled.svg"
import { InterfaceFolderFile } from "~/classes/file/InterfaceFolderFile"

const props = defineProps<{ fragment: SearchV1IntentFragment }>()
const { addInterfaceEventListener, removeInterfaceEventListener } = useEvents()

if (props.fragment.type !== "folderRef")
  throw new Error("Non folder fragment passed")

const folder = ref<InterfaceFolderFile>(InterfaceFolderFile.blank(props.fragment.value))

function onProperties(response: FilePropertiesResponseDatagram) {
  if (response.file && response.file.fileId === folder.value.fileId)
    folder.value = new InterfaceFolderFile(response.file)
}

onMounted(() => {
  addInterfaceEventListener("filePropertiesResponse", onProperties)
  if (props.fragment.type !== "folderRef")
    throw new Error("Non folder fragment passed")
  sendFilePropertiesRequest({
    requestId: generateWorkerRequestId(),
    fileId: props.fragment.value,
    selection: { everything: true },
    pathSelection: { properties: [], groups: [] },
  })
})
onUnmounted(() => {
  removeInterfaceEventListener("filePropertiesResponse", onProperties)
})
</script>

<template>
  <div class="folder">
    <folder-icon class="icon" />
    <span v-if="folder.isBlank()" class="name">Loading...</span>
    <span class="name">{{ folder.name() }}</span>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.folder
  @apply text-[#{$global-violet-primary}] flex text-ellipses-inline items-center gap-1 max-w-20
  .name
    @apply text-ellipses-inline
  .icon
    @apply h-4 w-4 flex-none
</style>

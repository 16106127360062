<script setup lang="ts">
const { gestureState } = useGestures()

function dragStyle() {
  if (!gestureState.value.mouseDown || !gestureState.value.mousePosition)
    return {}

  const {
    clientX: downX,
    clientY: downY,
  } = gestureState.value.mouseDown
  const {
    clientX: posX,
    clientY: posY,
  } = gestureState.value.mousePosition

  return {
    left: px(Math.min(downX, posX)),
    width: px(Math.abs(downX - posX)),
    top: px(Math.min(downY, posY)),
    height: px(Math.abs(downY - posY)),
  }
}
</script>

<template>
  <div
    v-if="gestureState.gesture === 'drag-select'"
    class="pointer-events-none absolute border border-0.5 border-blue-hex/75 rounded-sm bg-blue-hex/5"
    z="gesture-layer"
    :style="dragStyle()"
  />
</template>

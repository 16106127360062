<script setup lang="ts">
import { Property, PropertySchema, propertyName } from "~/classes/entity"

const props = defineProps<{ file: InterfaceFile }>()
const { corePropertyDisplay, generativePropertyDisplay, panelState } = usePanel()
const { createAndEditFileNotesProperty } = useProperty()

const fileNotesProperty = computed(() => props.file.property(Property.FILE_NOTES))
const fileExifProperty = computed(() => props.file.property(Property.FILE_EXIF_JSON))
</script>

<template>
  <div class="details-view">
    <div class="section">
      <file-thumbnail
        :file
        :container-width="panelState.width"
        :container-height="panelState.width"
      />
    </div>
    <div class="section">
      <div class="header">
        Properties
      </div>
      <template
        v-for="{ editable, monospace, inline, propertyId }, key in corePropertyDisplay"
        :key
      >
        <div
          v-if="file.hasProperty(propertyId)"
          class="view"
          :class="{ inline }"
        >
          <div class="label">
            {{ propertyName(propertyId) }}
          </div>
          <panel-details-property-path v-if="propertyId === Property.VIRTUAL_PATH" :file />
          <panel-details-property-dimensions
            v-else-if="propertyId === Property.VIRTUAL_DIMENSIONS"
            :file
          />
          <panel-details-property-editable-text
            v-else-if="editable"
            :file
            :schema-type="file.property(propertyId)!.schema"
            :property-id="(propertyId as Property)"
            :data-property-id="propertyId"
            wrap
          />
          <panel-details-property-text
            v-else
            :class="{ monospace }"
            class="value"
            :property="file.property(propertyId)!"
          />
        </div>
      </template>
    </div>
    <div class="section">
      <div class="header">
        Details
      </div>
      <div class="view-full">
        <div class="label">
          Tags
        </div>
        <file-property-tag-editor
          :file
          class="panel-tag-editor"
        />
      </div>
      <div class="view-full">
        <div class="label">
          Notes
        </div>
        <panel-details-property-editable-text
          v-if="fileNotesProperty"
          class="panel-notes-editor"
          :file
          :schema-type="PropertySchema.NullableStringValue"
          :property-id="Property.FILE_NOTES"
          :data-property-id="Property.FILE_NOTES"
          newlines
        />
        <div
          v-else
          class="button"
          @click="createAndEditFileNotesProperty(file)"
        >
          <icon-action
            size="xs"
            type="plus"
          />
          <div>Add Notes</div>
        </div>
      </div>
    </div>
    <div v-if="file.hasGenerativeMetadata()" class="section">
      <div class="header">
        Generation Data
      </div>
      <template v-for="{ monospace, multiline, editable, inline }, propertyId in generativePropertyDisplay" :key="propertyId">
        <div
          v-if="file.hasProperty(propertyId)"
          class="view"
          :class="{ inline }"
        >
          <div class="label">
            {{ propertyName(propertyId) }}
          </div>
          <panel-details-property-editable-text
            v-if="editable"
            :file
            :schema-type="file.property(propertyId)!.schema"
            :property-id="(propertyId as Property)"
            :multiline
            :data-property-id="propertyId"
            wrap
          />
          <panel-details-property-text
            v-else
            :class="{ monospace, multiline }"
            class="value"
            :property="file.property(propertyId)!"
          />
        </div>
      </template>
    </div>
    <div v-if="fileExifProperty && fileExifProperty.value.type === PropertySchema.ExifJsonStringValue" class="section">
      <div class="header">
        EXIF
      </div>
      <template v-for="value, key in fileExifProperty.value.exif" :key>
        <div
          class="view"
          :class="{
            inline: key !== 'UserComment',
          }"
        >
          <div class="label">
            {{ key }}
          </div>
          <div
            class="value"
            :class="{
              'inline': key !== 'UserComment',
              'multiline user-comment': key === 'UserComment',
            }"
          >
            {{ value }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.details-view
  @apply overflow-auto h-full

.section
  @apply p-[#{$panel-properties-p-default}] flex flex-col gap-[#{$panel-properties-gap-properties}]
  & + .section
    @apply border-t border-t-[#{$menu-b-separator}]
  .header
    @apply t-[#{$global-t-default}] t-menu-lg font-medium
  .label
    @apply flex-none font-normal text-[#{$global-t-tertiary}] text-menu-sm

  .view
    @apply flex flex-col gap-[#{$panel-properties-gap-properties}]
  .view.inline
    @apply flex-row justify-between
    .value
      @apply text-ellipses-inline t-panel-sm

  .value
    @apply
    &.multiline
      @apply whitespace-normal overflow-auto max-h-40 break-words
    &:not(.multiline)
      @apply text-ellipses-inline t-panel-sm
    &.monospace
      @apply

  .button
    @apply px-2 py-1 rounded-md inline-flex text-[#{$global-t-quaternary}] items-center gap-1 cursor-pointer
    &:hover
      @apply bg-[#{$menu-bg-item-hover}] text-[#{$global-t-default}]

.panel-tag-editor, .panel-notes-editor
  @apply mt-1
</style>

<script setup lang="ts">
const { myself } = useUser()
const { navigateToHome, navigateToArchive } = useNavigation()
const { cacheState } = useCache()
</script>

<template>
  <div class="self">
    <div v-if="!cacheState.isWebsocketConnected" class="offline-marker">
      Offline
    </div>
    <sidebar-self-avatar />
  </div>
  <div class="roots">
    <sidebar-entry-file
      v-if="myself.home"
      :ordered-id="0"
      :file="myself.home"
      @click="navigateToHome()"
    />
    <sidebar-entry-file
      v-if="myself.archive"
      :ordered-id="0"
      :file="myself.archive"
      @click="navigateToArchive()"
    />
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.self
  @apply p-4 ml-auto flex items-center z-navigation-layer

.offline-marker
  @apply text-xs font-medium  px-2.5 h-6 flex items-center rounded-full mr-2 ring-1 ring-amber-light text-$global-amber-primary

.roots
  @apply mt-[#{$sidebar-m-header-top}] p-[#{$sidebar-p-main}] flex flex-col gap-[#{$sidebar-gap-items-container}]
</style>

<script setup lang="ts">
const { mainViewState, getChildByFileId } = useMainView()
const { thumbnailPreviewImage } = useGlobals()
const { navigationState } = useNavigation()

const console = useLogger("TransitionPreview", theme.colors.green.hex)

async function onEnter(el: Element, done: any) {
  await sleep(100)
  return done()
}
async function onLeave(el: Element, done: any) {
  if (transitionType() === "open-image") {
    console.log("Exited transition", navigationState.value.file)
    const fileId = navigationState.value.file?.fileId
    if (!fileId)
      return console.error("No file id found for transition")
    const folderItem = getChildByFileId(el, fileId)
    if (!folderItem)
      return console.error("No folder item found for transition")
    const image = folderItem.getElementsByClassName(thumbnailPreviewImage)[0]
    if (!image)
      return console.error("No image found for transition")
  }
  await sleep(100)
  return done()
}

function transitionType() {
  if (mainViewState.value.state === "preview")
    return "open-image"
  else
    return "close-image"
}
</script>

<template>
  <client-only>
    <Transition
      :name="transitionType()"
      @enter="onEnter"
      @leave="onLeave"
    >
      <slot />
    </Transition>
  </client-only>
</template>

<style lang="sass">
@import '~/assets/styles/generated/variables.sass'
.open-image-enter-active
  transition: all 0.1s
  position: absolute

.open-image-leave-active
  transition: all 0.1s
  position: absolute

.open-image-enter-from
  transform: scale(0.1)

.open-image-leave-to
  display: none

.close-image-enter-active
  transition: all 0.1s
  position: absolute

.close-image-leave-active
  transition: all 0.1s
  position: absolute

// .close-image-enter-from

.close-image-leave-to
  transform: scale(0.1)
</style>

<script setup lang="ts">
const props = defineProps<{
  file: InterfaceFile
  orderedId: number
  dragOver?: boolean
}>()
const { isSelectedSidebar, isSelectedMainView } = useSelection()
const sidebarSelection = computed(() => isSelectedSidebar(props.file.fileId))
const mainViewSelection = computed(() => isSelectedMainView(props.file.fileId))
</script>

<template>
  <div
    :data-file-id="file.fileId"
    :data-ordered-id="orderedId"
    class="entry-file"
    :class="{ sidebarSelection, mainViewSelection }"
  >
    <file-item class="file-inner" :file />
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.entry-file
  @apply text-[#{$sidebar-t-item}] t-sidebar-item -m-1.5 p-1.5 rounded-[#{$sidebar-br-item-hovered}] cursor-pointer
  &:hover
    @apply bg-[#{$sidebar-bg-item-hovered}]
  height: calc($sidebar-h-entry + 12px)
.file-inner
  @apply gap-[#{$sidebar-gap-item-icon}]

.sidebarSelection
  @apply bg-[#{$global-blue-primary}]
  &:hover
    @apply bg-[#{$global-blue-primary}]
.mainViewSelection
  @apply bg-[#{$global-blue-accent}]
  &:hover
    @apply bg-[#{$global-blue-accent}]
</style>

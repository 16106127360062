<script setup lang="ts">
</script>

<template>
  <div class="shortcut">
    <slot />
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.shortcut
  @apply bg-[#{$menu-bg-item-hover}] rounded-[#{$menu-br-item-shortcut}] px-[#{$menu-px-item-shortcut}] text-[#{$menu-t-item-shortcut}] h-[#{$menu-h-shortcut}] t-menu-2xs inline-flex items-center vertical-middle
</style>

<script setup lang="ts">
</script>

<template>
  <div class="dropdown-item">
    <div class="dropdown-container">
      <div class="dropdown-info">
        <slot />
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.dropdown-item
  @apply h-[#{$menu-context-h-item}] w-full text-[#{$menu-t-header}] t-menu-sm flex
  .dropdown-container
    @apply flex flex-grow items-center rounded-[#{$menu-br-item}] m-[#{$menu-m-hover-outer}] p-[#{$menu-p-hover-inner}] w-full
  .dropdown-info
    @apply h-[#{$menu-context-h-item}] w-full flex
</style>

<script setup lang="ts">
import { PropertyColor } from "~/classes/generated/interface"
import { enumEq } from "~/utils/helpers"

const value = defineModel<PropertyColor>({ required: true })

const active = ref(false)
const select = ref<HTMLDivElement>()

function handleSelect(key: PropertyColor) {
  value.value = key
  toggle()
}

function handleClickOutside(event: MouseEvent) {
  if (!event.target)
    return
  if (event.target === select.value || select.value?.contains(event.target as any))
    return
  if (active.value)
    toggle()
}

function toggle() {
  if (active.value) {
    active.value = false
    document.body.removeEventListener("click", handleClickOutside)
  }
  else {
    active.value = true
    document.body.addEventListener("click", handleClickOutside)
  }
}
</script>

<template>
  <div ref="select" class="relative inline-block text-left">
    <div class="inline-select-button t-menu-sm" @click="toggle()">
      <div
        class="color"
        :class="{
          'bg-$global-t-default': enumEq(value, PropertyColor.Default),
          'bg-$global-steel-primary': enumEq(value, PropertyColor.Steel),
          'bg-$global-amber-primary': enumEq(value, PropertyColor.Amber),
          'bg-$global-green-primary': enumEq(value, PropertyColor.Green),
          'bg-$global-teal-primary': enumEq(value, PropertyColor.Teal),
          'bg-$global-blue-primary': enumEq(value, PropertyColor.Blue),
          'bg-$global-purple-primary': enumEq(value, PropertyColor.Purple),
          'bg-$global-pink-primary': enumEq(value, PropertyColor.Pink),
          'bg-$global-red-primary': enumEq(value, PropertyColor.Red),
        }"
      />
    </div>
    <div
      v-show="active"
      class="menu-dropdown-container absolute -right-1 -top-1 inline-select-dropdown"
    >
      <div
        v-for="option in [
          PropertyColor.Default,
          PropertyColor.Steel,
          PropertyColor.Amber,
          PropertyColor.Green,
          PropertyColor.Teal,
          PropertyColor.Blue,
          PropertyColor.Purple,
          PropertyColor.Pink,
          PropertyColor.Red,
        ]"
        :key="option"
        class="inline-select-option"
        @click="handleSelect(option)"
      >
        <div
          class="color"
          :class="{
            'halo': enumEq(option, value),
            'bg-$global-t-default': enumEq(option, PropertyColor.Default),
            'bg-$global-steel-primary': enumEq(option, PropertyColor.Steel),
            'bg-$global-amber-primary': enumEq(option, PropertyColor.Amber),
            'bg-$global-green-primary': enumEq(option, PropertyColor.Green),
            'bg-$global-teal-primary': enumEq(option, PropertyColor.Teal),
            'bg-$global-blue-primary': enumEq(option, PropertyColor.Blue),
            'bg-$global-purple-primary': enumEq(option, PropertyColor.Purple),
            'bg-$global-pink-primary': enumEq(option, PropertyColor.Pink),
            'bg-$global-red-primary': enumEq(option, PropertyColor.Red),
          }"
        />
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.inline-select-button
  @apply hover:bg-[#{$menu-bg-item-hover}] text-[#{$global-t-secondary}]
  @apply flex cursor-pointer items-center gap-1 rounded-[#{$menu-br-item}] p-1 px-1.5 -m-1 -mx-1.5 h-6
  .color
    @apply w-2.5 h-2.5 rounded-full

.inline-select-dropdown
  @apply gap-[#{$menu-gap-items}] p-[#{$menu-p-default}] flex-row

.inline-select-option
  @apply hover:t-[#{$global-t-default}]
  @apply flex cursor-pointer items-center h-5 -m-1 p-1 gap-2
  @apply rounded-[#{$menu-br-item}] hover:bg-[#{$menu-bg-item-hover}]
  .color
    @apply w-2.5 h-2.5 rounded-full
  .halo
    @apply outline outline-1.5 outline-$global-blue-primary
  .icon
    @apply ml-auto h-4 w-4 text-[#{$global-bg-icon-secondary}]
</style>

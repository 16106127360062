<script setup lang="ts">
import folder64 from "~/assets/images/files/folder/poly_folder@64.webp"
import unknown64 from "~/assets/icons/mime/unknown-64.png"

const props = defineProps<{ files: InterfaceFile[] }>()
const width = 32
const num = Math.min(props.files.length, 3)
const gap = 4
const imageWidth = width - (gap * (num - 1))

function webDisplayableImage(file: InterfaceFile) {
  const uri = file.clientUri()?.toLowerCase()
  if (!uri)
    return false
  for (const ext of [".jpeg", ".jpg", ".webp", ".png"]) {
    if (uri.endsWith(ext))
      return true
  }
  return false
}
// TODO: take up to first N
// Gap in the view is really subtle, how to get past that?
</script>

<template>
  <div class="file-set">
    <div
      v-for="file in files.slice(0, num)"
      :key="file.fileId"
      class="file-icon flex items-center justify-center"
      :style="{
        width: px(imageWidth),
        height: px(imageWidth),
        marginRight: px(gap - imageWidth),
      }"
    >
      <img v-if="file.isFolder()" :src="folder64">
      <img v-else-if="file.isImage() && file.icon64CropUri()" :src="file.icon64CropUri()">
      <img
        v-else-if="webDisplayableImage(file)"
        :src="file.clientUri()"
        :style="{
          maxWidth: px(imageWidth),
          maxHeight: px(imageWidth),
        }"
      >
      <img v-else :src="unknown64">
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.file-set
  @apply flex items-center
  .file-icon
    @apply flex-none
    img
      @apply outline-[#{$menu-tray-b-icon-mask}]
      @apply rounded-[#{$menu-tray-br-file-set-file}]
      @apply outline
</style>

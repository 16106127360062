<script setup lang="ts">
const { sortedSidebarFiles } = useSidebar()
const { gestureState } = useGestures()
const { myself } = useUser()

const sortedFiles = computed(() => {
  if (!myself.value.user)
    return []
  return sortedSidebarFiles()
})
const addToSidebar = computed(() => gestureState.value.sidebarFilesDropBetween !== undefined)
// const dragOverBeforeId = computed(() => gestureState.value.sidebarDropBetween?.[0])
const dragOverAfterId = computed(() => gestureState.value.sidebarFilesDropBetween?.[1])
</script>

<template>
  <div class="main">
    <div class="header">
      <div class="label">
        Pinned Files
      </div>
    </div>
    <div class="files">
      <template v-for="[orderedId, file] in sortedFiles" :key="orderedId">
        <sidebar-entry-drop-placeholder
          v-if="addToSidebar && dragOverAfterId === orderedId"
        />
        <sidebar-entry-file :ordered-id :file />
      </template>
      <sidebar-entry-drop-placeholder
        v-if="addToSidebar && dragOverAfterId === undefined"
      />
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.main
  @apply px-[#{$sidebar-p-main}]
.header
  @apply flex t-sidebar-item text-[#{$sidebar-t-header}] mt-[#{$sidebar-m-header-top}] mb-[#{$sidebar-m-header-bottom}]
.files
  @apply flex flex-col gap-[#{$sidebar-gap-items-container}]
</style>

<script setup lang="ts">
const { sortedContents } = useMainView()
</script>

<template>
  <div class="spacer" />
  <div class="icon-list">
    <main-icon-file
      v-for="file in sortedContents"
      :key="file.fileId"
      :file
    />
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.spacer
  @apply pointer-events-none w-full
  @apply h-[#{$nav-h-default}]
.icon-list
  @apply flex flex-wrap p-[#{$page-tree-p-main}] gap-[#{$page-tree-p-main}]
</style>

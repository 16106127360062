<script setup lang="ts">
import { IconPlus } from "@tabler/icons-vue"

const { sharedFolderState } = useSharedFolders()
</script>

<template>
  <div class="main">
    <div class="header">
      <div class="label">
        Shared
      </div>
      <div class="new-space">
        <interface-dropdown
          v-model:active="sharedFolderState.new.dialogOpen"
          position="left-top"
        >
          <template #default="{ open }">
            <button class="add" @click="open()">
              <icon-plus :size="14" :stroke-width="2" />
            </button>
          </template>
          <template #menu>
            <sidebar-shared-folder-creation-dropdown />
          </template>
        </interface-dropdown>
      </div>
    </div>
    <div class="spaces">
      <sidebar-entry-shared-folder
        v-for="(sharedFolderInfo, key) in sharedFolderState.all"
        :key
        :shared-folder-info
      />
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.main
  @apply px-[#{$sidebar-p-main}]
.header
  @apply flex t-sidebar-item text-[#{$sidebar-t-header}] mt-[#{$sidebar-m-header-top}] mb-[#{$sidebar-m-header-bottom}]
.new-space
  @apply flex items-center justify-center ml-auto
  .add
    @apply -mr-1  text-t-header-info px-1 py-0.5 hover:bg-accent-strongest rounded-sm hover:text-[#{$global-t-default}] cursor-pointer
.spaces
  @apply flex flex-col gap-[#{$sidebar-gap-items-container}]
</style>

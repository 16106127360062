<script setup lang="ts">
const { mainViewState } = useMainView()

const parentId = computed(() => mainViewState.value.rootFolder)
</script>

<template>
  <div class="spacer" />
  <div v-if="parentId" class="tree-view">
    <main-tree-list :parent-id />
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.spacer
  @apply pointer-events-none w-full
  @apply h-[#{$nav-h-default}]
.tree-view
  @apply flex flex-col p-[#{$page-tree-p-main}] w-full
</style>

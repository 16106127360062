<script setup lang="ts">
import type { Property } from "~/classes/entity"
import { PropertySchema } from "~/classes/entity"
import type { InterfaceFile } from "~/classes/file/InterfaceFile"
import type { PropertyColor, PropertySize } from "~/classes/generated/interface"

const props = defineProps<{
  file: InterfaceFile
  propertyId: Property
  schema: PropertySchema
  size: PropertySize
  color: PropertyColor
  selected?: boolean
  diff?: InterfaceFile
}>()
const { viewModePropertySizes, viewModePropertyColors } = useViewMode()
const { stopEditing, propertyEditorState, isEditingProperty, getPropertyAsDiff } = useProperty()
const editable = ref()
const editing = computed(() => isEditingProperty(props.file.fileId, props.propertyId))
const property = computed(() => props.file.property(props.propertyId))
const propertyDiff = computed(() => props.diff ? getPropertyAsDiff(props.file, props.diff, props.propertyId) : undefined)

watch(editing, isEditing => isEditing && setTimeout(() => { editable.value.$el.focus() }, 100))
</script>

<template>
  <div :class="{ 'max-w-full': !editing }">
    <interface-content-editable
      v-if="propertyEditorState.newValue.type === PropertySchema.NonNullableStringValue"
      v-show="editing"
      ref="editable"
      v-model="propertyEditorState.newValue.nonNullableString"
      tag="div"
      contenteditable="plaintext-only"
      wrap
      spellcheck="false"
      class="no-scrollbar content editing"
      :class="[viewModePropertySizes[size].textCss, viewModePropertyColors[color].textCss]"
      @returned="stopEditing()"
    />
    <interface-content-editable
      v-else-if="propertyEditorState.newValue.type === PropertySchema.NullableStringValue"
      v-show="editing"
      ref="editable"
      v-model="propertyEditorState.newValue.nullableString"
      tag="div"
      contenteditable="plaintext-only"
      wrap
      spellcheck="false"
      class="no-scrollbar content editing"
      :class="[viewModePropertySizes[size].textCss, viewModePropertyColors[color].textCss]"
      @returned="stopEditing()"
    />
    <div
      v-show="!editing"
      v-if="property"
      class="content text-ellipses-inline min-line-height-one"
      :class="[viewModePropertySizes[size].textCss, viewModePropertyColors[color].textCss]"
    >
      <div v-if="propertyDiff" class="diff">
        <span
          v-for="diffItem, key in propertyDiff"
          :key
          :class="{
            deleted: diffItem[0] < 0,
            same: diffItem[0] === 0,
            added: diffItem[0] > 0,
          }"
        >
          {{ diffItem[1] }}
        </span>
      </div>
      <span v-else-if="property.value.type === PropertySchema.NonNullableStringValue">
        {{ property.value.nonNullableString }}
      </span>
      <span v-else-if="property.value.type === PropertySchema.NullableStringValue">
        {{ property.value.nullableString || "" }}
      </span>
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.content
  @apply rounded-[#{$page-properties-br-overlay}] px-1.5 py-0.5
.editing
  @apply flex items-center overflow-y-hidden bg-transparent
  &:focus
    @apply ring-inset outline-none ring-2 ring-$global-blue-primary

.diff
  @apply inline
  .deleted
    @apply bg-[#{$global-red-secondary}]
  .added
    @apply hidden
</style>

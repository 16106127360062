<script setup lang="ts">
const props = defineProps<{ job: DownloadJob }>()
const {
  jobFractionFinished,
  jobFractionStarted,
  jobFractionSucceeded,
  clearNotificationById,
} = useNotifications()
const files = computed(() => Object.values(props.job.sources))
</script>

<template>
  <div class="container">
    <div class="preview">
      <file-set class="w-full h-full" :files />
    </div>
    <div class="content">
      <div class="info">
        <span v-if="len(files) === 1">
          {{ files[0].name() || "Downloading..." }}
        </span>
        <span v-else>
          {{ files.length }} Items
        </span>
      </div>
      <div v-if="job.status === 'downloading'" class="progress-bar">
        <div class="background" />
        <div
          class="part started"
          :style="{ width: fracToPct(jobFractionStarted(job)) }"
        />
        <div
          class="part finished"
          :style="{ width: fracToPct(jobFractionFinished(job)) }"
        />
        <div
          class="part succeeded"
          :style="{ width: fracToPct(jobFractionSucceeded(job)) }"
        />
      </div>
      <div v-if="job.status === 'downloading'" class="detail t-menu-tray-detail">
        <icon-action type="arrow-down" class="text-$global-blue-primary" />
        <span>Downloading</span>
      </div>
      <div v-if="job.status === 'complete'" class="detail t-menu-tray-detail">
        <icon-action type="check" class="text-$global-green-primary" />
        <span>Downloaded</span>
      </div>
    </div>
    <div class="close" @click.stop.prevent="clearNotificationById(job.id)">
      <menu-action-icon type="cross" />
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.t-menu-tray-detail
  @apply text-[#{$global-fs-sm}]
  @apply text-[#{$menu-tray-t-detail}]
  @apply fw-[450]

.container
  @apply flex px-4 py-2 gap-3 items-center w-full
  .preview
    @apply w-8 h-8 flex-none rounded-lg items-center justify-center
  .content
    @apply flex flex-col gap-[6px] w-full min-w-0
    .info
      @apply text-ellipses-inline
    .progress-bar
      @apply h-[#{$menu-tray-h-progress}]
      @apply relative w-full bg-[#{$menu-tray-bg-progress}] rounded-full overflow-hidden
      .part
        @apply absolute top-0 left-0 h-full
      .started
        @apply bg-$global-blue-primary
      .finished
        @apply bg-$global-red-primary
      .succeeded
        @apply bg-$global-green-primary
    .detail
      @apply t-menu-tray-detail flex gap-1
</style>

<script setup lang="ts">

</script>

<template>
  <div class="drop-placeholder" />
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.drop-placeholder
  @apply h-[2.5px] bg-[#{$sidebar-bg-item-drag}] rounded-full -mx-[6px]
</style>

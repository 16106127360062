<script setup lang="ts">
const { sharedFolderState, createSpace } = useSharedFolders()
const { sharedFolderCreationTestId: spaceCreationTestId } = useTestId()

const handleInput = ref<HTMLFormElement | null>(null)

const valid = computed(() => {
  const { name, handle } = sharedFolderState.value.new
  if (!name)
    return false
  if (handle.length < 3 || handle.length > 128)
    return false
  return handleInput.value?.checkValidity()
})

function onCreateButtonPressed() {
  if (!valid.value)
    return
  createSpace()
}
</script>

<template>
  <div class="menu-dropdown-container create-space-container" :data-testid="spaceCreationTestId">
    <menu-section>
      <div class="header">
        <div class="title">
          Create a Shared Folder
        </div>
        <div class="description">
          Shared folders let you collaborate on your files either privately with friends or publicly with the world (public mode launching soon).
        </div>
      </div>
      <form ref="handleInput" @submit.prevent>
        <div class="input">
          <div class="label">
            Name
          </div>
          <interface-input
            v-model.trim="sharedFolderState.new.name"
            placeholder="Enter a name for your space"
          />
        </div>
        <div class="input">
          <div class="label">
            Handle
          </div>
          <interface-input
            v-model.trim="sharedFolderState.new.handle"
            pattern="[a-z0-9]+([\-_\~\.][a-z0-9]+)*"
            leading
            placeholder="Enter a name for your space"
            @keydown.enter.prevent="onCreateButtonPressed()"
          >
            <template #leading>
              <span class="text-text-secondary">@</span>
            </template>
          </interface-input>
        </div>
      </form>
    </menu-section>
    <div
      class="create-button"
      :class="{ valid }"
      @click="onCreateButtonPressed()"
    >
      <interface-spinner
        v-if="sharedFolderState.new.loading"
        class="h-4 w-4"
      />
      <div>Create this space</div>
    </div>
  </div>
</template>

<style lang="sass">
@import '~/assets/styles/generated/variables.sass'

.create-space-container
  @apply w-80 relative overflow-hidden
  .header
    @apply space-y-1
    .title
      @apply t-menu-lg mb-2 text-[#{$global-t-default}]
    .description
      @apply text-sm text-[#{$global-t-secondary}]

  .input
    @apply mt-3
    .label
      @apply t-menu-sm mb-1
    input:invalid
      @apply ring-[#{$global-red-primary}] focus:ring-[#{$global-red-primary}]\!

  .create-button
    @apply bg-[#{$global-blue-primary}] py-3 flex items-center justify-center gap-2 cursor-pointer text-[#{$global-t-default}]
    &:not(.valid)
      @apply bg-[#{$global-steel-secondary}]
</style>

<script setup lang="ts">
import IconCrystalBall from "~/assets/icons/crystal-ball-line.svg?component"

const { searchState } = useSearch()

const searches = computed(() => searchState.value.suggestions.searches ?? [])
const selected = computed(() => searchState.value.suggestions.selection)
</script>

<template>
  <menu-section>
    <menu-header label="Suggested Searches" />
    <search-helper-item
      v-for="search, i in searches"
      :key="search.searchId()"
      :selected="selected === i"
    >
      <div class="search-inner">
        <search-intent :search />
      </div>
    </search-helper-item>
  </menu-section>
  <menu-separator />
  <menu-section>
    <menu-header label="Commands" />
    <search-helper-item shortcut="/">
      Folders & Files
    </search-helper-item>
    <search-helper-item shortcut="#">
      Tags
    </search-helper-item>
    <search-helper-item shortcut="@">
      People
    </search-helper-item>
    <search-helper-item shortcut="+">
      Properties
    </search-helper-item>
  </menu-section>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.search-inner
  @apply gap-[#{$menu-search-gap-item-left}] flex items-center

.crystal-ball
  @apply h-5 w-5 flex-none

.navigate-action-section
  @apply inline-flex gap-[6px]
</style>

<script setup lang="ts">
import IconCrystalBall from "~/assets/icons/crystal-ball-line.svg?component"

const props = defineProps<{
  search: InterfaceSearch
  orderedId: number
  dragOver?: boolean
}>()
const { isSelectedSidebar, isSelectedMainView } = useSelection()
const sidebarSelection = computed(() => isSelectedSidebar(props.search.searchId()))
const mainViewSelection = computed(() => isSelectedMainView(props.search.searchId()))
</script>

<template>
  <div
    :data-search-id="search.searchId()"
    :data-ordered-id="orderedId"
    class="entry-search"
    :class="{ sidebarSelection, mainViewSelection }"
  >
    <icon-crystal-ball class="crystal-ball" />
    <search-intent class="search-inner" :search />
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.entry-search
  @apply text-[#{$sidebar-t-item}] t-sidebar-item -m-1.5 p-1.5 hover:bg-[#{$sidebar-bg-item-hovered}] rounded-[#{$sidebar-br-item-hovered}] cursor-pointer flex items-center gap-[#{$sidebar-gap-item-icon}]
  height: calc($sidebar-h-entry + 12px)

.crystal-ball
  @apply h-5 w-5 flex-none

.search-inner
  @apply gap-[#{$sidebar-gap-item-icon}]

.sidebarSelection
  @apply bg-[#{$global-blue-primary}] hover:bg-[#{$global-blue-primary}]
.mainViewSelection
  @apply bg-[#{$global-blue-accent}] hover:bg-[#{$global-blue-accent}]
</style>

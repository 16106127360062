<script setup lang="ts">
// TODO: find out why this is breaking in dev mode, and why we need to wrap in client-only?

import { IconArrowDown } from "@tabler/icons-vue"

const { notificationState, hasProgress, progressFraction, openNotificationTray, closeNotificationTray } = useNotifications()

const erroring = computed(() => {
  for (const alert of Object.values(notificationState.value.alerts))
    if (alert.type === "error-alert")
      return true
  return false
})

function progressStyle() {
  const progress = progressFraction() * 100
  return {
    background: `conic-gradient(${theme?.colors.blue.hex} ${pct(progress)}, 0, transparent ${pct(100 - progress)})`,
  }
}
</script>

<template>
  <div class="tray-pill" :class="{ erroring }">
    <client-only>
      <interface-dropdown
        v-model:active="notificationState.tray.isOpen"
        position="bottom-left"
      >
        <template #default="{ active }">
          <nav-pill-view class="tray">
            <div
              class="pill-progress"
              :style="hasProgress() ? progressStyle() : {}"
            >
              <div class="pill-progress-mask" />
            </div>
            <div class="pill-inner" @click="active ? closeNotificationTray() : openNotificationTray()">
              <icon-arrow-down
                class="flex-none"
                :size="20"
                :stroke-width="2"
              />
            </div>
          </nav-pill-view>
        </template>
        <template #menu>
          <nav-tray-dropdown />
        </template>
      </interface-dropdown>
    </client-only>
    <transition
      appear
      mode="out-in"
      enter-active-class="transition duration-1500 ease-out"
      enter-from-class="transform scale-100 opacity-100"
      enter-to-class="transform scale-125 opacity-0"
      leave-active-class="transition duration-0"
      leave-from-class="opacity-0"
      leave-to-class="opacity-0"
    >
      <div
        v-if="notificationState.tray.showRipple"
        class="ripple"
      />
    </transition>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.tray-pill
  app-region: no-drag
  @apply z-navigation-layer relative

.tray
  @apply aspect-square
  .pill-progress
    @apply absolute w-full h-full  rounded-full pointer-events-none p-0.5
  .pill-progress-mask
    @apply w-full h-full bg-[#{$page-bg-secondary}] rounded-full
  .pill-inner
    @apply h-full w-full items-center justify-center rounded-full flex hover:bg-[#{$pill-bg-button-hover}] cursor-pointer z-1 text-$global-blue-primary
.ripple
  @apply absolute left-0 top-0 h-8 w-8 rounded-full outline-0.75 outline-$global-blue-primary outline -z-1 bg-blue-stroke
.erroring
  .ripple
    @apply outline-$global-red-primary bg-red-stroke
</style>

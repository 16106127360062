<script setup lang="ts">

</script>

<template>
  <div class="section">
    <slot />
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.section
  @apply p-[#{$menu-p-default}] w-full gap-[#{$menu-gap-items}] flex flex-col
</style>

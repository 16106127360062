<script setup lang="ts">
const { targetFiles } = usePanel()
const files = computed(() => targetFiles())

// TODO: search page default details
</script>

<template>
  <panel-details-single v-if="files.length === 1" :file="files[0]" />
  <panel-details-batch v-else-if="files.length" :files />
  <div v-else />
</template>

<script setup lang="ts">
import { isDesktop } from "~/utils/platform"

const { isSignedIn } = useUser()
const { onAppMounted } = useNavigation()

// We need a way to tell if we are in windows, mac, linux, or web.
// On windows, we currently need to set the background as fully
// transparent, as the mica background effect will take over. In
// mac, we need to strategically set the color such that the
// unfocused color also looks good.
useHead({
  bodyAttrs: {
    class: () => `bg-app ${isDesktop() ? "desktop" : "browser"}`,
  },
  titleTemplate: (titleChunk) => {
    if (!titleChunk)
      return "Poly: The Intelligent Cloud Image Browser | Join the Waitlist"
    if (titleChunk.length > 60)
      return `${titleChunk.slice(0, 40)}... | Poly: Intelligent Cloud Storage`
    return `${titleChunk} | Poly: Intelligent Cloud Storage`
  },
})

onMounted(async () => onAppMounted(true))
</script>

<template>
  <loading-startup-screen v-if="!isSignedIn()" class="layout-default-main" />
  <layout-main v-else class="layout-default-main" />
  <nuxt-page />
</template>

<style lang="sass">
@import '~/assets/styles/generated/variables.sass'

.layout-default-main
  @apply h-screen w-full flex flex-col t-base

.bg-app
  @apply text-base select-none t-[#{$global-t-default}]
  &.browser
    @apply bg-[#{$page-bg-primary}]
  &.desktop
    @apply bg-[#{$page-bg-primary-desktop}]
</style>

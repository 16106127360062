<script setup lang="ts">
const globals = useGlobals()
const { sidebarState } = useSidebar()
const { sidebarTestId } = useTestId()
</script>

<template>
  <div
    :id="globals.sidebarId"
    :data-testid="sidebarTestId"
    :style="{ width: px(sidebarState.width) }"
    class="sidebar"
  >
    <sidebar-self-view />
    <sidebar-shared-folder-view />
    <sidebar-pinned-files />
    <sidebar-pinned-searches />
    <sidebar-dragger />
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.sidebar
  @apply border-r-[#{$page-b-divider}] bg-[#{$sidebar-bg-default}] relative flex flex-col border-r
</style>

<script setup lang="ts">
import { Property, PropertySchema } from "~/classes/entity"

const props = defineProps<{
  files: InterfaceFile[]
}>()

function width(file: InterfaceFile) {
  const wProp = file.property(Property.WIDTH)
  if (!wProp || wProp.value.type !== PropertySchema.PositiveIntegerValue)
    return undefined
  return wProp.value.positiveInteger
}

function height(file: InterfaceFile) {
  const hProp = file.property(Property.HEIGHT)
  if (!hProp || hProp.value.type !== PropertySchema.PositiveIntegerValue)
    return undefined
  return hProp.value.positiveInteger
}

const firstWidth = computed(() => width(props.files[0]))
const firstHeight = computed(() => height(props.files[0]))
const sameWidth = computed(() => all(props.files, f => width(f) === firstWidth.value))
const sameHeight = computed(() => all(props.files, f => height(f) === firstHeight.value))
</script>

<template>
  <div class="dimensions">
    <div v-if="sameWidth">
      {{ firstWidth }}
    </div>
    <panel-details-property-multi-placeholder v-else />
    <div class="x-mark">
      ×
    </div>
    <div v-if="sameHeight">
      {{ firstHeight }}
    </div>
    <panel-details-property-multi-placeholder v-else />
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.dimensions
  @apply inline-flex gap-1
.x-mark
  @apply text-[#{$global-t-tertiary}]
</style>

<script setup lang="ts">
import { Property } from "~/classes/entity"
import { PropertySize } from "~/classes/generated/interface"

const { selectedFiles } = useSelection()
const { peekWindowId, peekBackgroundId } = useGlobals()
const { gestureLayers } = useGestures()
const { closePeek, peekState, toggleProperties, toggleDiff } = usePeek()
const { viewModeState } = useViewMode()

// TODO: have an "N items" view, e.g. gallery etc.

const files = computed(selectedFiles)
const properties = computed(
  () => viewModeState.value.current.show.filter(p => p.propertyId !== Property.VIRTUAL_PREVIEW),
)

/** Diffing is only supported with two files, so we know it's [0] or [1]  */
function diffTarget(file: InterfaceFile) {
  if (!peekState.value.isShowingDiffs)
    return undefined
  if (files.value[0].fileId === file.fileId)
    return files.value[1]
  return files.value[0]
}
</script>

<template>
  <div :id="peekBackgroundId" class="peek-background">
    <div :id="peekWindowId" class="peek-window">
      <div class="peek-window-inner">
        <div
          v-for="file, i in files"
          :key="file.fileId"
          class="peek-image-container"
        >
          <div class="peek-image-header">
            <div class="peek-icon-list">
              <div class="peek-icon" @click="toggleProperties()">
                <icon-action
                  v-if="peekState.isShowingProperties"
                  size="xs"
                  type="chevron-down"
                  class="transform rotate-180"
                />
                <icon-action
                  v-else
                  size="xs"
                  type="chevron-down"
                />
              </div>
            </div>
            <div v-if="!peekState.isShowingProperties" class="peek-header-properties">
              <file-property-view
                v-for="{ propertyId, color } in viewModeState.current.show"
                :key="propertyId"
                :property-id
                :size="PropertySize.Small"
                :file
                :color
                text-alignment="left"
              />
            </div>
            <div v-if="i === files.length - 1" class="peek-actions">
              <div
                class="peek-icon"
                @click="toggleDiff()"
              >
                <icon-action
                  size="xs"
                  type="highlight"
                />
              </div>
              <div
                class="peek-icon"
                @click="closePeek()"
              >
                <icon-action
                  size="xs"
                  type="cross"
                />
              </div>
            </div>
          </div>
          <div class="peek-image">
            <renderer-view :file :gesture-layer="gestureLayers.peek + 1" />
            <div v-if="peekState.isShowingProperties" class="peek-image-properties">
              <div
                v-for="{ propertyId, size, color } in properties"
                :key="propertyId"
                class="peek-image-property"
              >
                <file-property-view
                  :property-id
                  :size
                  :file
                  :color
                  :diff="diffTarget(file)"
                  text-alignment="left"
                  bg
                  multiline
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.peek-background
  @apply  absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center z-overlay-layer
.peek-window
  @apply w-3/4 h-3/4 bg-[#{$menu-bg-default}] border pointer-events-auto shadow-menu border-[#{$menu-b-outline}] rounded-[#{$menu-br-default}] backdrop-blur-[#{$menu-blur-default}] relative p-[#{$page-peek-gap-item}]
.peek-window-inner
  @apply flex h-full w-full rounded-b-[#{$page-peek-br-item}] overflow-hidden gap-[#{$page-peek-gap-item}]
.peek-actions
  @apply flex-none h-[#{$page-peek-h-header}] flex ml-auto
.peek-icon
  @apply h-[#{$page-peek-h-header}] w-[#{$page-peek-h-header}] p-0.5 rounded-[#{$page-peek-br-item}] cursor-pointer text-[#{$global-bg-icon-secondary}]
  &:hover
    @apply bg-[#{$menu-bg-item-hover}]

.peek-image-container
  @apply flex flex-col gap-[#{$page-peek-gap-item}] w-full h-full min-w-0
.peek-image-header
  @apply h-[#{$page-peek-h-header}] flex-none flex w-full
.peek-header-properties
  @apply flex gap-0.25 text-ellipses-inline items-center
.peek-image
  @apply w-full h-full bg-[#{$page-peek-bg-item}] relative
.peek-image-properties
  @apply absolute top-0 left-0 p-1 max-w-full pointer-events-none h-full overflow-hidden flex flex-col gap-[#{$page-properties-gap-file}]
.peek-image-property
  @apply pointer-events-auto w-full flex
</style>

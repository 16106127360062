<!-- eslint-disable unicorn/prefer-dom-node-text-content -->
<script setup lang="ts">
const props = defineProps<{
  tag: string
  modelValue: string
  contenteditable?: boolean | "plaintext-only"
  html?: boolean
  newlines?: boolean
  wrap?: boolean
}>()

const emit = defineEmits<{
  "returned": [value: string]
  "update:modelValue": [value: string]
}>()

function replaceAll(str: string, search: string, replacement: string) {
  return str.split(search).join(replacement)
}

const element = ref<HTMLElement | null>()

function currentContent() {
  return props.html
    ? element.value!.innerHTML
    : element.value!.innerText || ""
}

function updateContent(newContent: string) {
  if (props.html)
    element.value!.innerHTML = newContent
  else
    element.value!.innerText = newContent
}

function update(content?: string) {
  // console.log(`Updating content: ${content}`)
  emit("update:modelValue", content ?? "")
}

function onPaste(event: any) {
  event.preventDefault()
  let text = (event.originalEvent || event).clipboardData.getData("text/plain")
  if (!props.newlines) {
    text = replaceAll(text, "\r\n", " ")
    text = replaceAll(text, "\n", " ")
    text = replaceAll(text, "\r", " ")
  }
  window.document.execCommand("insertText", false, text)
}
function onKeypress(event: any) {
  if (event.key === "Enter" && !props.newlines) {
    event.preventDefault()
    // console.log('Updated with', currentContent())
    emit("returned", currentContent())
  }
}

onMounted(() => updateContent(props.modelValue ?? ""))
watch(() => props.modelValue, (newVal) => {
  if (newVal !== currentContent())
    updateContent(newVal ?? "")
})
watch(() => props.html, () => updateContent(props.modelValue ?? ""))
watch(() => props.tag, () => updateContent(props.modelValue ?? ""), { flush: "post" })
</script>

<template>
  <component
    :is="tag"
    ref="element"
    :contenteditable
    :class="{ wrap, newlines }"
    @input="update(currentContent())"
    @paste="onPaste"
    @keypress="onKeypress"
  />
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.wrap
  @apply break-all\!
.newlines
  @apply whitespace-pre-line\!
</style>

<script setup lang="ts">
const { panelState } = usePanel()
const { navIgnoreMainViewGestures, panelId } = useGlobals()
</script>

<template>
  <div
    :id="panelId"
    :style="{ width: px(panelState.width) }"

    class="panel"
  >
    <panel-dragger :class="[navIgnoreMainViewGestures]" />
    <panel-waterfall-view
      v-if="panelState.selected === 'waterfall'"
    />
    <panel-details-view
      v-else-if="panelState.selected === 'details'"
      :class="[navIgnoreMainViewGestures]"
    />
    <panel-history-view
      v-else-if="panelState.selected === 'history'"
      :class="[navIgnoreMainViewGestures]"
    />
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.panel
  @apply flex-none z-navigation-layer border-l border-l-[#{$page-b-divider}] bg-[#{$sidebar-bg-default}] relative
</style>

<script setup lang="ts">
import chevronOpen from "~/assets/icons/viewmode/tree/open.svg"
import chevronClosed from "~/assets/icons/viewmode/tree/closed.svg"

defineProps<{
  open?: boolean
}>()
const { mainViewTreeChevronClass: mainViewTreeChevron } = useGlobals()
</script>

<template>
  <div
    v-if="open"
    class="chevron open"
    :class="[mainViewTreeChevron]"
  >
    <chevron-open />
  </div>
  <div
    v-else
    class="chevron"
    :class="[mainViewTreeChevron]"
  >
    <chevron-closed />
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.chevron
  @apply w-4 h-4 -m-0.5 p-0.5 cursor-pointer rounded-1 text-[#{$page-tree-bg-chevron-closed}]
  &:hover
    @apply bg-[#{$page-tree-bg-chevron-hover}] text-[#{$page-tree-bg-chevron-open}]
  &.open
    @apply text-[#{$page-tree-bg-chevron-open}]
</style>

<script setup lang="ts">
const { viewModeState, viewModes, isCustomSort, clearViewModeSorts } = useViewMode()
const { navViewModeButtonTestId } = useTestId()

const sorting = computed(isCustomSort)
</script>

<template>
  <interface-dropdown position="bottom-right">
    <template #default="{ toggle }">
      <nav-pill-view class="viewmode-container">
        <nav-pill-button
          v-if="sorting"
          class="group sort-indicator"
          @click="clearViewModeSorts()"
        >
          <icon-action
            size="xs"
            type="sort"
            class="group-hover:hidden"
          />
          <icon-action
            size="xs"
            type="cross"
            class="hidden group-hover:block"
          />
        </nav-pill-button>
        <nav-pill-button
          :data-testid="navViewModeButtonTestId"
          class="group viewmode"
          @click="toggle()"
        >
          <component :is="viewModes[viewModeState.current.mode].icon" />
          <div>
            {{ viewModes[viewModeState.current.mode].name }}
          </div>
        </nav-pill-button>
      </nav-pill-view>
    </template>
    <template #menu>
      <nav-view-mode-dropdown />
    </template>
  </interface-dropdown>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.viewmode-container
  @apply gap-2
.viewmode
  @apply px-1.5

.sort-indicator
  @apply rounded-full bg-[#{$global-blue-accent}] text-[#{$global-blue-primary}]
  &:hover
    @apply text-[#{$pill-t-default}]
</style>

<script setup lang="ts">
const props = defineProps<{
  parentId: string
}>()
const { sortedContents } = useMainView()

/** This should be sorted as well, given that we're filtering a sorted object  */
const files = computed(() => sortedContents.value.filter(f => f.parentId() === props.parentId))
</script>

<template>
  <div v-if="files.length" class="tree-list">
    <main-tree-file
      v-for="file in files"
      :key="file.fileId"
      :file
    />
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.tree-list
  @apply flex flex-col gap-[#{$page-tree-gap-items-md}] pt-[#{$page-tree-gap-items-md}]
</style>

<script setup lang="ts">
import { Property } from "~/classes/entity"
import { InterfaceFile } from "~/classes/file"

// TODO: only request a subset of items, only the top / first N (N+1 to show a "see more")

const props = defineProps<{
  file: InterfaceFile
}>()
const { addInterfaceEventListener, removeInterfaceEventListener } = useEvents()
const { navigationState, navigateToFile } = useNavigation()
const { downloadFiles } = useTransferManager()
const { peekFiles } = usePeek()
const siblings = ref<InterfaceFile[]>([])
const requestId = ref<string>()
const maxItems = 11 // retrieve one more than 10 for a "show all"

function sendParentListRequest() {
  console.log("Getting parent list request", navigationState.value.file)
  const file = navigationState.value.file
  const path = navigationState.value.file?.path()
  if (file === undefined || path === undefined)
    return console.error("No path or navigation file found, skipping list request")
  const files = [file, ...path]
  let parentId
  let isRoot = false
  files.forEach((file, index) => {
    if (file.fileId !== props.file.fileId)
      return
    const parent = files[index + 1]
    if (index === len(files) - 1)
      isRoot = true
    else if (parent)
      parentId = parent.fileId
  })
  if (isRoot)
    return console.info("Clicked on root, skipping sibling list request")
  if (!parentId)
    return console.error("No parent found for file, skipping list request")

  console.info("Sending folder list request for path")
  requestId.value = generateWorkerRequestId()
  const request: FolderListRequestDatagram = {
    requestId: requestId.value,
    folder: { fileId: parentId, properties: {} },
    selection: {
      properties: [Property.NAME, Property.IMG_THUMBNAIL_CROP_64X64, Property.MIME_TYPE],
    },
    pathSelection: { properties: [] },
    pageSize: maxItems, // only need top 11,
    // top-n // TODO: only request the top 11
    sort: Property.MODIFIED_AT,
    desc: true,
  }
  sendFolderListRequests([request])
}

function onParentListResponse(response: FolderListPageResponseDatagram) {
  const { notifyDatagramError } = useNotifications()
  if (response.requestId !== requestId.value || response.index !== 0)
    return
  console.log("Path context menu received a folder list response", response)
  if (response.error || response.files === undefined)
    return notifyDatagramError(response.error!, "Error occurred listing files")
  siblings.value = response.files.map(InterfaceFile.from).filter(f => f.fileId !== props.file.fileId)
}

onMounted(() => {
  addInterfaceEventListener("folderListPageResponse", onParentListResponse)
  sendParentListRequest()
})
onUnmounted(() => {
  removeInterfaceEventListener("folderListPageResponse", onParentListResponse)
})
</script>

<template>
  <template v-if="siblings.length">
    <menu-section>
      <context-menu-dropdown-file-item
        v-for="sibling in siblings.slice(0, 10)"
        :key="sibling.fileId"
        :file="sibling"
        @click="navigateToFile(sibling)"
      />
    </menu-section>
    <menu-separator />
  </template>
  <menu-section>
    <context-menu-dropdown-item shortcut="␣" @click="peekFiles([file], 'other')">
      Quick View
    </context-menu-dropdown-item>
    <context-menu-dropdown-item>
      Open in Desktop (TODO)
    </context-menu-dropdown-item>
    <menu-separator />
    <context-menu-dropdown-item>
      Add to Pinned (TODO)
    </context-menu-dropdown-item>
    <menu-separator />
    <context-menu-dropdown-item>
      Cut
    </context-menu-dropdown-item>
    <context-menu-dropdown-item>
      Copy
    </context-menu-dropdown-item>
    <context-menu-dropdown-item @click="downloadFiles([file])">
      Download
    </context-menu-dropdown-item>
  </menu-section>
  <menu-separator />
  <menu-section>
    <context-menu-dropdown-file-status :file />
  </menu-section>
</template>

<script setup lang="ts">
import type { Content, Editor, JSONContent } from "@tiptap/vue-3"
import { EditorContent, useEditor } from "@tiptap/vue-3"
import Document from "@tiptap/extension-document"
import Text from "@tiptap/extension-text"
import Paragraph from "@tiptap/extension-paragraph"
import Placeholder from "@tiptap/extension-placeholder"
import { EditableTag } from "~/classes/file/properties/EditableTag"
import { PropertySchema } from "~/classes/entity"

const props = defineProps<{
  file: InterfaceFile
  focusOnMount?: boolean
}>()

const console = useLogger("TagEditor", theme.colors.red.hex)
const editor = useEditor({
  content: fileTagsAsContents(props.file),
  extensions: [
    Document,
    Paragraph,
    Text,
    Placeholder,
    EditableTag.createNode(),
  ],
  editorProps: {
    handleKeyDown: (_, event) => onKeyDown(editor.value!, event),
  },
  onUpdate: () => onUpdated(),
})
const { updateFile } = useFileSystem()
const { getTagPropertyId } = useCache()
const { myself } = useUser()

function onKeyDown(editor: Editor, event: KeyboardEvent) {
  if (event.key === "Enter") {
    const cursor = editor.state.selection.$from
    const from = cursor.start()
    const to = cursor.end()
    const value = cursor.node().textContent.trim()
    if (!value)
      return true
    if (tags().has(value))
      return console.warn("Tag is already in list, skipping")
    editor
      .chain()
      .deleteRange({ from, to })
      .insertContent([
        { type: EditableTag.TAG, attrs: { value } },
        { type: "text", text: " " }, // must have space otherwise tiptap can't create it
      ])
      .focus()
      .run()
    return true
  }
  return false
}

/** This editor's current tags */
function tags(): Set<string> {
  if (!editor.value)
    throw new Error("No editor value")
  const editorJson = editor.value?.getJSON()
  const tags = new Set<string>()
  for (const node of editorJson.content || []) {
    if (node.type !== EditableTag.TAG)
      continue
    const text = node.attrs?.value
    if (!text)
      continue
    tags.add(text)
  }
  return tags
}

/** When the editor text is updated */
async function onUpdated() {
  if (!editor.value)
    throw new Error("No editor value")
  if (!myself.value.user)
    throw new Error("Not logged in")
  const tagsToAdd = tags()
  const tagsToRemove = props.file.tags()
  for (const tag of tagsToAdd.intersection(tagsToRemove)) {
    tagsToAdd.delete(tag)
    tagsToRemove.delete(tag)
  }
  const updates: FileProperty[] = []
  for (const add of tagsToAdd)
    updates.push({
      propertyId: await getTagPropertyId(add),
      version: 0,
      value: { type: PropertySchema.HashableTagValue, hashableTag: add },
      timestamp: Date.now(),
      schema: PropertySchema.HashableTagValue,
      author: myself.value.user.userId,
      pending: true,
    })
  for (const add of tagsToRemove)
    updates.push({
      propertyId: await getTagPropertyId(add),
      version: 0,
      value: { type: PropertySchema.HashableTagValue, hashableTag: add },
      timestamp: Date.now(),
      schema: PropertySchema.HashableTagValue,
      author: myself.value.user.userId,
      pending: true,
      deleted: true,
    })
  if (!updates.length)
    return
  console.log("Updating tags to add and remove", tagsToAdd.values(), tagsToRemove.values())
  updateFile(props.file, updates)
}

function focusOnTagEditor() {
  editor.value?.commands.focus("end")
}

function fileTagsAsContents(file: InterfaceFile): JSONContent {
  const content: JSONContent[] = []
  for (const value of file.tags()) {
    content.push({
      type: EditableTag.TAG,
      attrs: { value },
    })
    content.push({
      type: "paragraph",
      content: [],
    })
  }
  return {
    type: "doc",
    content,
  }
}

/** Computes whether the tags are different then reloads the editor if so */
function reloadTagEditor(file: InterfaceFile, oldFile?: InterfaceFile) {
  if (file.fileId === oldFile?.fileId) {
    const tags = file.tags()
    const oldTags = oldFile.tags()
    if ((tags.size === oldTags.size) && tags.isSubsetOf(oldTags))
      return console.warn("File tags are the same, returning...")
  }
  editor.value?.chain().setContent(fileTagsAsContents(file)).blur().run()
}

watch(() => props.file, reloadTagEditor)
onMounted(() => {
  // The timeout here should ideally match the transition duration for the
  // search bar
  if (props.focusOnMount)
    setTimeout(focusOnTagEditor, 100)
})
</script>

<template>
  <div class="tag-editor">
    <editor-content :editor class="editor-content" />
  </div>
</template>

<style lang="sass">
@import '~/assets/styles/generated/variables.sass'

.tag-editor
  .tiptap
    @apply flex flex-wrap gap-0.5 items-center
    &.ProseMirror-focused
      outline: none
    p.is-editor-empty:first-child::before
      content: 'Add tags (enter to save)...'
      float: left
      height: 0
      pointer-events: none
      @apply text-[#{$global-t-quaternary}] font-normal
</style>

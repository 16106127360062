<script setup lang="ts">
import { InterfaceFolderFile } from "~/classes/file/InterfaceFolderFile"

const { setPathViewToFile } = useNavigation()
const { navigationState } = useNavigation()
const { addInterfaceEventListener, removeInterfaceEventListener } = useEvents()
const { navPathViewTestId } = useTestId()
const fullPath = computed(() => {
  const file = navigationState.value.file
  if (!file || file.isBlank())
    return []
  return [file, ...(file.path() || [])]
})

function onContents(response: FolderListPageResponseDatagram) {
  if (!response.files || !response.path || response.error)
    return console.warn("Skipping path update", response.error)
  if (response.folderId !== navigationState.value.file?.fileId)
    return

  const [parent, ...parentPath] = response.path
  parent.path = parentPath
  const folder = new InterfaceFolderFile(parent)
  folder.mergeWith(navigationState.value.file)
  setPathViewToFile(folder)
}

onMounted(() => addInterfaceEventListener("folderListPageResponse", onContents))
onUnmounted(() => removeInterfaceEventListener("folderListPageResponse", onContents))
</script>

<template>
  <nav-pill-view
    v-if="fullPath.length"
    class="overflow-hidden"
    :data-testid="navPathViewTestId"
  >
    <template v-for="(file, i) in [...fullPath].reverse()" :key="file.fileId">
      <nav-pill-button
        :data-path-file-id="file.fileId"
        class="path-pill-button"
        :class="{
          focused: i === fullPath.length - 1,
          default: i !== fullPath.length - 1,
        }"
      >
        <file-item :file class="file" />
      </nav-pill-button>
      <div v-if="i !== fullPath.length - 1" class="pill-item h-full flex-none px-3 pointer-events-none">
        <div class="separator" />
      </div>
    </template>
  </nav-pill-view>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.path-pill-button
  @apply flex-none pr-1.5
  &.focused
    @apply text-[#{$pill-path-t-focused}]
  &.default
    @apply text-[#{$pill-path-t-default}]
.separator
  @apply border-l-[#{$pill-path-b-separator}] h-full w-0 flex-none rotate-22 transform border-l

.file
  @apply max-w-40 h-full py-0.5 gap-[#{$pill-gap-default}]
</style>

<script setup lang="ts">
const props = defineProps<{
  min: number
  max: number
}>()
const value = defineModel<number>({ required: true })

const active = ref(false)
const select = ref<HTMLDivElement>()

function handleClickOutside(event: MouseEvent) {
  if (!event.target)
    return
  if (event.target === select.value || select.value?.contains(event.target as any))
    return
  if (active.value)
    toggle()
}

function toggle() {
  if (active.value) {
    active.value = false
    document.body.removeEventListener("click", handleClickOutside)
  }
  else {
    active.value = true
    document.body.addEventListener("click", handleClickOutside)
  }
}

function onUpdate(target: HTMLInputElement) {
  const scale: number = Number.parseInt(target.value) / 100
  value.value = props.min + scale * (props.max - props.min)
}
</script>

<template>
  <div ref="select" class="relative inline-block text-left">
    <div class="inline-select-button t-menu-sm" @click="toggle()">
      <div> {{ round(value, 2) }} </div>
      <icon-action
        size="2xs"
        type="chevron-down"
        class="-mr-1"
      />
    </div>
    <div
      v-show="active"
      class="menu-dropdown-container absolute -right-1 -top-1 inline-select-dropdown"
    >
      <div class="inline-select-inner">
        <input
          id="myRange"
          type="range"
          min="0"
          max="100"
          value="50"
          class="slider h-1.5 w-full rounded-full"
          @input="(e) => onUpdate(e.target as any)"
        >
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.inline-select-button
  @apply hover:bg-[#{$menu-bg-item-hover}] text-[#{$global-t-secondary}]
  @apply flex cursor-pointer items-center gap-1 rounded-[#{$menu-br-item}] p-1 px-1.5 -m-1 -mx-1.5
  .color
    @apply w-2.5 h-2.5 rounded-full

.inline-select-dropdown
  @apply gap-[#{$menu-gap-items}] flex-row w-30
.inline-select-inner
  @apply rounded-[#{$menu-br-default}] px-2 py-1

.slider
  -webkit-appearance: none
  appearance: none
  outline: none
  &::-webkit-slider-thumb
    -webkit-appearance: none
    appearance: none
    border-radius: 50%
    width: 12px
    height: 12px
    background: $global-t-default
    cursor: pointer
  &::-moz-range-thumb
    border-radius: 50%
    width: 12px
    height: 12px
    background: $global-t-default
    cursor: pointer
</style>

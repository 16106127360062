<script setup lang="ts">
import folder64 from "~/assets/images/files/folder/poly_folder@64.webp"

// TODO:
// [ ] Change the "gap 2px" to a variable'd property

const props = defineProps<{
  file: InterfaceFile
  open?: boolean
  parent?: boolean
}>()

const { isSelected } = useSelection()
const selected = computed(() => isSelected(props.file.fileId))
const { viewModeState } = useViewMode()
</script>

<template>
  <div
    class="column-item-container"
    :class="{ selected, open, parent }"
    :data-file-id="file.fileId"
  >
    <template v-if="file.isFolder()">
      <img :src="folder64" class="column-file-icon">
    </template>
    <template v-else>
      <img :src="(file as InterfaceImageFile).icon64CropUri()" class="column-file-icon">
    </template>
    <div class="column-properties">
      <file-property-view
        v-for="{ propertyId, size, color } in viewModeState.current.show.slice(1)"
        :key="propertyId"
        :property-id
        :size
        :file
        :color
        :selected
        text-alignment="left"
      />
    </div>
    <div v-if="file.isFolder()" class="icon">
      <icon-action size="2xs" type="chevron-right" />
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.column-item-container
  @apply flex items-center gap-[2px] overflow-hidden cursor-pointer -m-1 p-1 rounded-[#{$page-column-br-file}]
  &:hover
    @apply bg-[#{$page-column-bg-item-hover}]
  &.selected
    @apply bg-$global-blue-primary

.column-properties
  @apply flex items-center min-w-0 text-ellipses-inline
.column-file-icon
  @apply w-5 h-5 rounded-sm
.column-file-text
  @apply text-ellipses-inline

.icon
  @apply ml-auto -mr-0.75 text-[#{$global-bg-icon-secondary}] flex-none
.selected
  .icon
    @apply text-[#{$global-t-default}]
</style>

import { Node, mergeAttributes } from "@tiptap/core"
import type { DOMOutputSpec, Node as ProseMirrorNode } from "@tiptap/pm/model"
import { PluginKey } from "@tiptap/pm/state"
import { VueNodeViewRenderer } from "@tiptap/vue-3"

import ImageRefComponent from "~/components/Search/Editor/ImageRef.vue"

export interface ImageRefOptions {
  HTMLAttributes: Record<string, any>
  renderText: (props: { options: ImageRefOptions, node: ProseMirrorNode }) => string
  renderHTML: (props: { options: ImageRefOptions, node: ProseMirrorNode }) => DOMOutputSpec
  // suggestion: Omit<SuggestionOptions, 'editor'>
}

export const ImageRefPluginKey = new PluginKey("imageRef")

export const ImageRef = Node.create<ImageRefOptions>({
  name: "imageRef",

  addOptions() {
    return {
      HTMLAttributes: {},
      renderText({ node }) {
        return `${node.attrs.fileId}`
      },
      renderHTML() {
        return ["span", this.HTMLAttributes]
      },
    }
  },
  group: "inline",
  inline: true,
  selectable: true,
  draggable: false,
  atom: true,
  addAttributes() {
    return {
      fileId: {
        default: null,
        parseHTML: element => element.getAttribute("file-id"),
        renderHTML: ({ fileId }) => ({ "file-id": fileId }),
      },
      uri: {
        default: null,
        parseHTML: element => element.getAttribute("uri"),
        renderHTML: ({ uri }) => ({ uri }),
      },
    }
  },

  addNodeView() {
    return VueNodeViewRenderer(ImageRefComponent)
  },

  parseHTML() {
    return [
      {
        tag: `image-ref`,
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "image-ref",
      mergeAttributes({}, this.options.HTMLAttributes, HTMLAttributes),
    ]
  },

  renderText({ node }) {
    return this.options.renderText({
      options: this.options,
      node,
    })
  },

  addKeyboardShortcuts() {
    return {
      Backspace: () => this.editor.commands.command(({ tr, state }) => {
        let isImageRef = false
        const { selection } = state
        const { empty, anchor } = selection

        if (!empty)
          return false

        state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
          if (node.type.name === this.name) {
            isImageRef = true
            // TODO: this retains the @ symbol when pressed backspace
            // tr.insertText(this.options.suggestion.char || '', pos, pos + node.nodeSize)
            tr.insertText(" ", pos, pos + node.nodeSize)

            return false
          }
        })

        return isImageRef
      }),
    }
  },
})

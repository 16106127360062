<script setup lang="ts">
import { useMouse } from "@vueuse/core"
import columnGrip from "~/assets/icons/column-grip.svg"

defineProps<{
  files: InterfaceFile[]
  /** Which column index you are, from left to right */
  index: number
  columnWidth: number
}>()
const { y } = useMouse()
const { mainViewColumnDraggerClass } = useGlobals()
</script>

<template>
  <div
    class="single-column"
    :style="{
      width: px(columnWidth),
    }"
  >
    <div class="scroll-container">
      <div class="spacer" />
      <div class="single-column-content">
        <main-column-file
          v-for="file in files"
          :key="file.fileId"
          :file
        />
      </div>
      <div class="spacer" />
    </div>
    <div
      :data-column-index="index"
      :class="[mainViewColumnDraggerClass]"
      class="divider-container group"
    >
      <div class="divider" />
      <column-grip class="grip" :style="{ top: px(y) }" />
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.spacer
  @apply pointer-events-none w-full
  @apply h-[#{$nav-h-default}]

.single-column
  @apply h-screen border-r border-r-[#{$page-column-bg-divider}] relative

.scroll-container
  @apply h-full overflow-auto

.single-column-content
  @apply p-[#{$page-column-p-files}] flex flex-col gap-[#{$page-column-gap-files}]

.divider-container
  @apply absolute h-full w-2 px-0.75 top-0 bottom-0 -right-1 z-gesture-overlay-layer hover:cursor-none

.divider
  @apply h-full w-full bg-[#{$global-blue-primary}] opacity-0 group-hover:opacity-100

.grip
  @apply absolute w-3.5 h-7 -my-3.5 text-[#{$global-blue-primary}] -right-0.75 opacity-0 group-hover:opacity-100
</style>

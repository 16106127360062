<script setup lang="ts">
const { mainViewState } = useMainView()
const { mainLayoutId } = useGlobals()
const { gestureState } = useGestures()
const { sharedFolderIdForCurrentFile } = useNavigation()
const { peekState } = usePeek()
const { panelState } = usePanel()
const { mainLayoutTestId } = useTestId()
const { initGestures, destroyGestures } = useGestures()
const { onSwitchedSpaces, onUserInfoResponse, onSharedFolderMembershipResponse } = useSharedFolders()
const { addInterfaceEventListener, removeInterfaceEventListener } = useEvents()
const { sidebarState } = useSidebar()

const resizing = computed(() => gestureState.value.gesture === "drag-divider")
watch(() => sharedFolderIdForCurrentFile(), onSwitchedSpaces, { immediate: true })
onMounted(() => {
  initGestures()
  addInterfaceEventListener("userFindResponse", onUserInfoResponse)
  addInterfaceEventListener("SharedFolderMembershipResponse", onSharedFolderMembershipResponse)
})
onUnmounted(() => {
  destroyGestures()
  removeInterfaceEventListener("userFindResponse", onUserInfoResponse)
  removeInterfaceEventListener("SharedFolderMembershipResponse", onSharedFolderMembershipResponse)
})
</script>

<template>
  <div
    :id="mainLayoutId"
    :data-testid="mainLayoutTestId"
    class="overflow-hidden"
    :class="{ resizing }"
  >
    <native-drag-region />
    <div class="main-screen">
      <sidebar-view v-if="sidebarState.visible" class="flex-none" />
      <div class="main-contents">
        <transition-preview>
          <main-view v-if="mainViewState.state === 'folder' || mainViewState.state === 'search-results'" />
          <preview-view v-else-if="mainViewState.state === 'preview'" />
          <div v-else>
            Unknown view state: {{ mainViewState.state }}
          </div>
        </transition-preview>

        <slot />

        <nav-view />
        <panel-pill />
      </div>
      <panel-view v-if="panelState.visible" />
    </div>
    <gesture-multi-select-drag-outline v-if="gestureState.gesture === 'drag-select'" />
    <gesture-dragged-files v-if="gestureState.gesture === 'drag-file'" />
    <gesture-dragged-search v-if="gestureState.gesture === 'drag-search'" />
    <peek-view v-if="peekState.visible" />
    <context-menu-view />
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.main-screen
  @apply w-full flex flex-1 overflow-hidden

.main-contents
  @apply relative w-full overflow-x-hidden

// When resizing a divider, we want to hide the mouse
.resizing
  @apply cursor-none
</style>

<script setup lang="ts">
import { watchThrottled } from "@vueuse/core"
import unknown512 from "~/assets/icons/mime/unknown-512.png"
import unknown192 from "~/assets/icons/mime/unknown-192.png"
import unknown64 from "~/assets/icons/mime/unknown-64.png"
import home512 from "~/assets/images/logo/logo.webp"
import archive512 from "~/assets/images/files/archive/poly_archive@512.webp"
import type { InterfaceUnknownFile } from "~/classes/file"
import { isSafari } from "~/utils/platform"

const props = defineProps<{
  file: InterfaceFile | InterfaceImageFile | InterfaceFolderFile | InterfaceUnknownFile
  containerWidth: number
  containerHeight: number
  cropped?: boolean
  selected?: boolean
  shadowed?: boolean
  flat?: boolean // The flat look used in grid and feed modes
}>()

let state = "thumbnail-loading" as "thumbnail-loading" | "thumbnail-loaded" | "uri-loading" | "uri-loaded" | "broken"

const console = useLogger("file-thumbnail-image", theme.colors.pink.hex)
const globals = useGlobals()
const fileThumbnail = ref<HTMLImageElement>()
const folder = computed(() => !props.file.isBlank() && props.file.isFolder())

/**
 * returns the best image we could use to render the object, given the height
 * and width of the container.
 */
function getBestImageUrl() {
  const { file, cropped } = props
  const minWidth = Math.min(props.containerWidth, file.imageWidth() || Number.MAX_SAFE_INTEGER)
  const minHeight = Math.min(props.containerHeight, file.imageHeight() || Number.MAX_SAFE_INTEGER)
  // TODO: improve
  const minDim = Math.max(minWidth, minHeight)
  let best: string | undefined
  if (minDim >= 512)
    best = cropped ? file.icon1536CropUri() : file.icon1536NativeUri()
  if (minDim >= 192)
    best = best || (cropped ? file.icon512CropUri() : file.icon512NativeUri())
  if (minDim >= 64)
    best = best || (cropped ? file.icon192CropUri() : file.icon192NativeUri())
  best = best || (cropped ? file.icon64CropUri() : file.icon64NativeUri())
  return best
}

function getBestUnknownUrl() {
  const { file } = props
  const minWidth = Math.min(props.containerWidth, file.imageWidth() || Number.MAX_SAFE_INTEGER)
  const minHeight = Math.min(props.containerHeight, file.imageHeight() || Number.MAX_SAFE_INTEGER)
  // TODO: improve
  const minDim = Math.max(minWidth, minHeight)
  if (minDim >= 256)
    return unknown512
  if (minDim >= 96)
    return unknown192
  return unknown64
}

/** Updates the image file based on container size, loaded state of uri, etc. */
async function updateImage() {
  if (!fileThumbnail.value || state === "uri-loaded")
    return
  else if (props.file.isHomeFolder()) {
    fileThumbnail.value!.src = home512
    state = "uri-loaded"
    return
  }
  else if (props.file.isArchiveFolder()) {
    fileThumbnail.value!.src = archive512
    state = "uri-loaded"
    return
  }
  else if (state === "thumbnail-loading" || state === "thumbnail-loaded") {
    const bestUri = getBestImageUrl()
    if (!bestUri)
      return console.error("Image has no best uri")
    if (fileThumbnail.value.src !== bestUri)
      fileThumbnail.value.src = bestUri
  }
  else if (state === "uri-loading") {
    // const uri = props.file.clientUri()
    const uri = props.file.clientUri()
    if (!uri)
      throw new Error("No uri")
    if (fileThumbnail.value.src !== uri)
      fileThumbnail.value.src = uri
  }
  else if (state === "broken")
    fileThumbnail.value.src = getBestUnknownUrl()
  else throw new Error(`Unknown state during update image: ${state}`)
}
async function onImageError() {
  console.log("Error loading file image", state, fileThumbnail.value?.src)
  if (state === "thumbnail-loaded" || state === "uri-loaded" || state === "broken")
    return
  else if (state === "thumbnail-loading") {
    state = "uri-loading"
    updateImage()
  }
  else if (state === "uri-loading") {
    state = "broken"
    updateImage()
  }
  else throw new Error(`Unknown state during image error: ${state}`)
}
async function onImageLoad() {
  if (state === "thumbnail-loaded" || state === "uri-loaded" || state === "broken")
    return
  else if (state === "thumbnail-loading")
    state = "thumbnail-loaded"
  else if (state === "uri-loading")
    state = "uri-loaded"
  else throw new Error(`Unknown state during image load: ${state}`)
}

/** If the file was changed, we need to replace the image, not just refresh it */
async function replaceImage() {
  state = "thumbnail-loading"
  updateImage()
}

onMounted(async () => updateImage())
watchThrottled(() => props.containerWidth, () => updateImage(), { throttle: 1000 / 45 })
watch(
  () => props.file,
  (newFile, oldFile) => newFile.fileId !== oldFile.fileId ? replaceImage() : updateImage(),
)
</script>

<template>
  <img
    ref="fileThumbnail"
    src=""
    draggable="false"
    class="object-contain object-center select-none"
    :class="{
      [globals.thumbnailPreviewImage]: true,
      safari: isSafari(),
      selected,
      shadowed,
      flat,
      folder,
    }"
    @error="onImageError()"
    @load="onImageLoad()"
  >
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.shadowed
  // On safari, the default filter seems to interfere with the hover/selection filter color,
  // causing weird artifacts. So, we detect if the user is on safari and remove this default
  // filter in that instance.
  // TODO: tauri desktop seems to not support our shim for detecting safari
  // &:not(.safari)
  //   filter: drop-shadow(-4px 2px 4px #10101040)
  &:hover
    @include alphaShadow(var(--global-blue-primary), 1px, drop-shadow(-4px 2px 4px #10101040))
  &.selected
    @include alphaShadow(var(--global-blue-primary), 2px, drop-shadow(-4px 2px 4px #10101040))

.flat.folder
  @apply bg-[#{$global-violet-accent}]
</style>

<script setup lang="ts">
import type { InterfaceSharedFolderInfo } from "~/classes/sharedFolder/InterfaceSharedFolderInfo"
import { InterfaceUser } from "~/classes/users/InterfaceUser"

// TODO: loading state for this so that we don't see it pop in asynchronously once user info
// is available

const { navSharingViewTestId } = useTestId()
const { sharedFolderState } = useSharedFolders()
const users = computed(() => Object.values(sharedFolderState.value.current!.users))
</script>

<template>
  <interface-dropdown position="bottom-right">
    <template #default="{ open }">
      <nav-pill-view v-if="len(users)">
        <nav-pill-button
          :data-testid="navSharingViewTestId"
          class="sharing-menu-button"
          @click="open()"
        >
          <div class="user-bubble-container">
            <user-avatar
              v-for="user of users"
              :key="user.userId"
              :user
              :size="24"
              class="user-bubble"
            />
          </div>
        </nav-pill-button>
      </nav-pill-view>
    </template>
    <template #menu>
      <nav-sharing-dropdown-view />
    </template>
  </interface-dropdown>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.user-bubble-container
  @apply flex h-full px-0.75 group flex-row-reverse justify-end
.user-bubble
  @apply outline outline-2 outline-[#212122] h-full group-hover:outline-[#66696d] z-1 -mx-1.5
  &:not(:last-child)
    @apply ml-0
</style>

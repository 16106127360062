<script setup lang="ts">
import type { Property } from "~/classes/entity"
import { PropertySchema } from "~/classes/entity"

const props = defineProps<{
  files: InterfaceFile[]
  propertyId: Property
}>()

if (props.files.length < 1)
  throw new Error("Cannot have zero files")

const firstProperty = computed(() => props.files[0].property(props.propertyId))
const allProperties = computed(() => props.files.map(f => f.property(props.propertyId)))
const sameProperty = computed(() => {
  if (props.files.length === 1)
    return true
  if (any(allProperties.value, v => v === undefined))
    return false
  const firstValue = JSON.stringify(firstProperty.value?.value)
  return all(allProperties.value, v => JSON.stringify(v?.value) === firstValue)
})
</script>

<template>
  <div v-if="sameProperty && firstProperty?.value.type === PropertySchema.SizeBytesValue">
    {{ formatBytes(firstProperty?.value.sizeBytes, 2) }}
  </div>
  <div v-else-if="sameProperty && firstProperty?.value.type === PropertySchema.UtcTimestampMillisValue">
    {{ (new Date(firstProperty?.value.utcTimestampMillis)).toLocaleString() }}
  </div>
  <div v-else-if="sameProperty && firstProperty?.value.type === PropertySchema.MimeTypeValue">
    {{ firstProperty?.value.mimeType }}
  </div>
  <div v-else-if="sameProperty && firstProperty?.value.type === PropertySchema.NonNullableStringValue">
    {{ firstProperty?.value.nonNullableString }}
  </div>
  <div v-else-if="sameProperty && firstProperty?.value.type === PropertySchema.NullableStringValue">
    {{ firstProperty?.value.nullableString }}
  </div>
  <div v-else-if="sameProperty && firstProperty?.value.type === PropertySchema.AnyIntegerValue">
    {{ firstProperty?.value.anyInteger }}
  </div>
  <div v-else-if="sameProperty">
    An error occurred...
  </div>
  <panel-details-property-multi-placeholder v-else />
</template>

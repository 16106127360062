<script setup lang="ts">
const { navigationState } = useNavigation()
const { downloadFiles } = useTransferManager()
</script>

<template>
  <menu-section>
    <context-menu-dropdown-item
      v-if="navigationState.file"
      @click="downloadFiles([navigationState.file])"
    >
      Download Image
    </context-menu-dropdown-item>
  </menu-section>
</template>

<script setup lang="ts">
import folder64 from "~/assets/images/files/folder/poly_folder@64.webp"

defineProps<{
  file: InterfaceFile
  selected?: boolean // for keyboard navigation purposes
}>()
const emit = defineEmits(["click"])
const { hideContextMenu } = useContextMenu()
function closeAndEmit() {
  hideContextMenu()
  emit("click")
}
</script>

<template>
  <button class="dropdown-item" @click="closeAndEmit">
    <file-item
      class="dropdown-container"
      :class="{ selected }"
      :file
    />
  </button>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.dropdown-item
  @apply h-[#{$menu-context-h-item}]
  @apply text-[#{$menu-t-item-default}]
  @apply w-full text-size-sm t-menu-sm flex
  &:hover
    @apply text-[#{$menu-t-item-hovered}]
  .dropdown-container
    @apply cursor-pointer rounded-[#{$menu-br-item}] hover:bg-[#{$menu-bg-item-hover}]  gap-1 h-full p-1.5 -m-1.5 gap-[#{$menu-path-gap-item}] flex-grow
    height: calc($menu-path-h-sibling-default + 12px)
  .selected
    @apply bg-[#{$menu-bg-default}]
</style>

<script setup lang="ts">
const { sortedSidebarSearches } = useSidebar()
const { gestureState } = useGestures()
const { myself } = useUser()

const sortedSearches = computed(() => {
  if (!myself.value.user)
    return []
  return sortedSidebarSearches()
})
const addToSidebar = computed(() => gestureState.value.sidebarDragOver && gestureState.value.searchDragged !== undefined)
</script>

<template>
  <div class="main">
    <div class="header">
      <div class="label">
        Pinned Searches
      </div>
    </div>
    <div class="searches">
      <template v-for="[orderedId, search] in sortedSearches" :key="orderedId">
        <sidebar-entry-search :ordered-id :search />
      </template>
      <sidebar-entry-drop-placeholder v-if="addToSidebar" />
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.main
  @apply px-[#{$sidebar-p-main}]

.header
  @apply flex t-sidebar-item text-[#{$sidebar-t-header}] mt-[#{$sidebar-m-header-top}] mb-[#{$sidebar-m-header-bottom}]

.searches
  @apply flex flex-col gap-[#{$sidebar-gap-items-container}]
</style>

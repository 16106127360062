<script setup lang="ts" generic="T">
import { enumEq } from "~/utils/helpers"

defineProps<{
  options: T[]
  label: (key: T) => string
  enabled: (key: T) => boolean
  menuWidth?: number
}>()
const emit = defineEmits<{
  selected: [option: T]
}>()

const active = ref(false)
const select = ref<HTMLDivElement>()

function handleSelect(key: T) {
  emit("selected", key)
  toggle()
}

function handleClickOutside(event: MouseEvent) {
  if (!event.target)
    return
  if (event.target === select.value || select.value?.contains(event.target as any))
    return
  if (active.value)
    toggle()
}

function toggle() {
  if (active.value) {
    active.value = false
    document.body.removeEventListener("click", handleClickOutside)
  }
  else {
    active.value = true
    document.body.addEventListener("click", handleClickOutside)
  }
}
</script>

<template>
  <div ref="select" class="relative inline-block text-left">
    <div @click="toggle()">
      <slot />
    </div>
    <div
      v-show="active"
      class="menu-dropdown-container absolute -left-1 -top-1 inline-select-dropdown"
      :style="{ width: menuWidth ? px(menuWidth) : undefined }"
    >
      <div
        v-for="option in options"
        :key="`${option}`"
        class="inline-select-option t-menu-sm"
        @click="handleSelect(option)"
      >
        <div>{{ label(option) }}</div>
        <icon-action
          v-if="enabled(option)"
          size="xs"
          type="check"
          class="icon"
        />
        <div v-else class="icon" />
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.inline-select-dropdown
  @apply gap-[#{$menu-gap-items}] p-[#{$menu-p-default}]

.inline-select-option
  @apply text-[#{$global-t-default}]
  @apply flex cursor-pointer items-center h-5 -m-1 p-1 gap-2
  @apply rounded-[#{$menu-br-item}] hover:bg-[#{$menu-bg-item-hover}]
  .icon
    @apply ml-auto h-4 w-4 text-[#{$global-bg-icon-secondary}]
</style>

<script setup lang="ts">
import iconFolderFilled from "~/assets/icons/folder-filled.svg"
import type { PropertySize } from "~/classes/generated/interface"

defineProps<{
  file: InterfaceFile
  size: PropertySize
}>()
const { navigateToFolder } = useNavigation()
const { viewModePropertySizes } = useViewMode()
</script>

<template>
  <div
    class="h-5.5 flex gap-1.25 text-sm -mx-1.5"
    :class="[viewModePropertySizes[size].textCss]"
  >
    <template
      v-for="(folder, i) in file.path()?.reverse()"
      :key="folder.fileId"
    >
      <div
        v-if="i !== 0"
        class="my-0.5 w-0 flex-none rotate-22 transform border-l border-l-border-secondary"
      />
      <div
        class="group flex cursor-pointer gap-1.25 rounded-md px-1.5 py-0.75"
        hover="bg-accent-strongest t-[#{$global-t-default}]"
        @click="navigateToFolder(folder)"
      >
        <icon-folder-filled
          class="w-4 text-text-secondary"
          group-hover="text-$global-violet-primary"
        />
        <div>
          {{ folder.name() }}
        </div>
      </div>
    </template>
  </div>
</template>

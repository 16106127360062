<script setup lang="ts">
const globals = useGlobals()
const { hideContextMenu, contextMenuState } = useContextMenu()
const { isPrimaryClick } = useGestures()
const menu = ref<HTMLDivElement>()

function onClickOutside(event: MouseEvent) {
  if (!event.target)
    return
  if (event.target === menu.value || menu.value?.contains(event.target as HTMLDivElement))
    return
  if (!isPrimaryClick(event))
    return
  hideContextMenu()
}

onMounted(() => document.addEventListener("click", onClickOutside))
</script>

<template>
  <div :id="globals.contextMenuId" ref="menu">
    <transition
      appear
      mode="out-in"
      enter-active-class="transition duration-50 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-30"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <!-- We use a 'key' here because we may have two context menus in between transitions -->
      <context-menu-dropdown-view
        v-if="contextMenuState.visible"
        :key="`${contextMenuState.x}|${contextMenuState.y}`"
        :x="contextMenuState.x"
        :y="contextMenuState.y"
      />
    </transition>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  shortcut?: string
  selected?: boolean // for keyboard navigation purposes
}>()
const emit = defineEmits(["click"])
</script>

<template>
  <button class="dropdown-item" @click="emit('click')">
    <div class="dropdown-container" :class="{ selected }">
      <span class="dropdown-text">
        <slot />
      </span>
      <menu-shortcut v-if="shortcut" class="dropdown-shortcut">
        {{ shortcut }}
      </menu-shortcut>
    </div>
  </button>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.dropdown-item
  @apply h-[#{$menu-context-h-item}]
  @apply text-[#{$menu-t-item-default}]
  @apply w-full text-size-sm t-menu-sm flex
  &:hover
    @apply text-[#{$menu-t-item-hovered}]
.dropdown-container
  @apply flex flex-grow items-center rounded-[#{$menu-br-item}] m-[#{$menu-m-hover-outer}] hover:bg-[#{$menu-bg-item-hover}] w-full px-[#{$menu-p-hover-inner}]
  height: calc($menu-p-hover-inner * 2 + $menu-context-h-item)
  &.selected
    @apply bg-[#{$menu-bg-item-hover}]
.dropdown-text
  @apply overflow-hidden break-words text-ellipsis whitespace-nowrap
.dropdown-shortcut
  @apply ml-auto flex-none
</style>

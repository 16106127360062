<script setup lang="ts">
import { useWindowSize } from "@vueuse/core"
import grip from "~/assets/icons/grip.svg"
import { Property } from "~/classes/entity"
import { PropertySize, ViewModeType } from "~/classes/generated/interface"

const {
  viewModeState,
  viewModes,
  switchViewMode,
  showableProperties,
  viewModePropertySizes,
  isShowingProperty,
  isSortingProperty,
  isFilteringProperty,
  showViewModeProperty,
  hideViewModeProperty,
  addViewModeSort,
  removeViewModeSort,
} = useViewMode()
const showablePropertyOptions = Object.values(showableProperties)
const sortablePropertyOptions = Object.values(showableProperties).filter(p => p.sortable)
const filterablePropertyOptions = Object.values(showableProperties).filter(p => p.filterable)

function onToggleShowProperty(propertyId: Property) {
  isShowingProperty(propertyId)
    ? hideViewModeProperty(propertyId)
    : showViewModeProperty(propertyId)
}
function onToggleSortProperty(propertyId: Property) {
  isSortingProperty(propertyId)
    ? removeViewModeSort(propertyId)
    : addViewModeSort(propertyId)
}
const { width } = useWindowSize()
</script>

<template>
  <div class="dropdown menu-dropdown-container">
    <menu-section>
      <menu-header label="Mode" />
      <div class="all-modes">
        <button
          v-for="mode in viewModes"
          :key="mode.type"
          class="mode"
          :class="{
            selected: mode.type === viewModeState.current.mode,
            icon: mode.type === ViewModeType.Icon,
            grid: mode.type === ViewModeType.Grid,
            detail: mode.type === ViewModeType.Detail,
            feed: mode.type === ViewModeType.Feed,
            column: mode.type === ViewModeType.Column,
            tree: mode.type === ViewModeType.Tree,
          }"
          @click="switchViewMode(mode.type)"
        >
          <component :is="mode.icon" class="mode-icon" />
          <div class="label">
            {{ mode.name }}
          </div>
        </button>
      </div>
    </menu-section>
    <template v-if="viewModeState.current.mode === ViewModeType.Detail">
      <menu-separator />
      <menu-section>
        <menu-header label="Options" />
        <div class="flex justify-between">
          <div class="t-menu-sm text-$global-t-secondary">
            Column Width (px)
          </div>
          <menu-selection-slider
            v-model="viewModeState.current.detailView.maxColumnWidth"
            :min="100"
            :max="width"
          />
        </div>
      </menu-section>
    </template>
    <menu-separator />
    <menu-section>
      <menu-header label="Properties" />
      <div
        v-for="show in viewModeState.current.show"
        :key="show.propertyId"
        class="property-row"
      >
        <div class="property-row-left t-base">
          <grip class="grip" />
          <icon-property :type="showableProperties[show.propertyId]!.icon" class="property-icon" />
          <div class="h-full flex items-center text-ellipses-inline">
            {{ showableProperties[show.propertyId]!.name }}
          </div>
        </div>
        <div class="property-row-right">
          <menu-selection-slider
            v-if="show.scale !== null"
            v-model="show.scale"
            :min="0"
            :max="1"
          />
          <menu-color-selection
            v-if="show.scale === null && show.propertyId !== Property.VIRTUAL_TAGS"
            v-model="show.color"
          />
          <menu-selection
            v-if="show.scale === null"
            v-model="show.size"
            :options="[
              PropertySize.Tiny,
              PropertySize.Small,
              PropertySize.Medium,
              PropertySize.Large,
              PropertySize.Huge,
              PropertySize.Giant,
            ]"
            :label="((k) => viewModePropertySizes[k].label)"
          />
          <icon-action
            v-if="show.propertyId === Property.VIRTUAL_PREVIEW"
            size="2xs"
            type="private"
            class="action"
          />
          <icon-action
            v-else
            action
            size="2xs"
            type="cross"
            class="action button"
            @click.stop.prevent="onToggleShowProperty(show.propertyId)"
          />
        </div>
      </div>
      <div class="inline-flex">
        <menu-multi-selection
          :options="showablePropertyOptions"
          :label="p => p.name"
          :enabled="p => isShowingProperty(p.propertyId)"
          :menu-width="200"
          @selected="p => onToggleShowProperty(p.propertyId)"
        >
          <div class="multi-select-button">
            <icon-action size="xs" type="plus" />
            <div>Property</div>
          </div>
        </menu-multi-selection>
      </div>
    </menu-section>
    <menu-separator />
    <menu-section>
      <menu-header label="Sort" />
      <div
        v-for="sort in viewModeState.current.sort"
        :key="sort.propertyId"
        class="property-row"
      >
        <div v-if="showableProperties[sort.propertyId]" class="property-row-left t-base">
          <grip class="grip" />
          <div class="filter-property-bubble">
            <icon-property :type="showableProperties[sort.propertyId]!.icon" />
            <div>
              {{ showableProperties[sort.propertyId]!.name }}
            </div>
          </div>
        </div>
        <div class="property-row-right">
          <menu-selection
            v-model="sort.descending"
            :options="[
              false, true,
            ]"
            :label="(d => d ? 'Descending' : 'Ascending')"
          />
          <icon-action
            action
            size="2xs"
            type="cross"
            class="action button"
            @click.stop.prevent="onToggleSortProperty(sort.propertyId)"
          />
        </div>
      </div>
      <div class="inline-flex">
        <menu-multi-selection
          :options="sortablePropertyOptions"
          :label="p => p.name"
          :enabled="p => isSortingProperty(p.propertyId)"
          :menu-width="200"
          @selected="p => onToggleSortProperty(p.propertyId)"
        >
          <div class="multi-select-button">
            <icon-action size="xs" type="plus" />
            <div>Property</div>
          </div>
        </menu-multi-selection>
      </div>
    </menu-section>
    <menu-separator />
    <menu-section>
      <menu-header label="Filter" />
      <div class="inline-flex">
        <menu-multi-selection
          :options="filterablePropertyOptions"
          :label="p => p.name"
          :enabled="p => isFilteringProperty(p.propertyId)"
          :menu-width="200"
          @selected="p => onToggleShowProperty(p.propertyId)"
        >
          <div class="multi-select-button">
            <icon-action size="xs" type="plus" />
            <div>Property</div>
          </div>
        </menu-multi-selection>
      </div>
    </menu-section>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.dropdown
  @apply w-[#{$menu-viewmode-w-default}]

.all-modes
  @apply grid grid-cols-2 gap-1 -m-1.5
.mode
  @apply h-8 w-full flex rounded-1.5 p-1.5 items-center gap-1.5 overflow-hidden
  @apply text-[#{$global-t-secondary}] fw-$global-fw-base-strong
  &:hover,&.selected
    &.icon
      @apply text-$global-green-primary bg-$global-green-accent
    &.grid
      @apply text-$global-amber-primary bg-$global-amber-accent
    &.detail
      @apply text-$global-purple-primary bg-$global-purple-accent
    &.feed
      @apply text-$global-blue-primary bg-$global-blue-accent
    &.column
      @apply text-$global-red-primary bg-$global-red-accent
    &.tree
      @apply text-$global-teal-primary bg-$global-teal-accent
  .mode-icon
    @apply flex-none w-[#{$pill-w-icon}] h-[#{$pill-w-icon}]

.property-row
  @apply flex items-center h-4 justify-between
.property-row-left
  @apply flex items-center h-4 gap-[#{$menu-viewmode-gap-property-edit}] cursor-grab
.property-row-right
  @apply flex items-center h-4 gap-[#{$menu-viewmode-gap-property-edit}]
  .action
    @apply text-[#{$menu-viewmode-bg-property-action}]
    &.button
      @apply cursor-pointer hover:bg-[#{$global-red-primary}] rounded-[2px] hover:text-[#{$global-t-default}]
.property-icon
  @apply text-[#{$global-bg-icon-secondary}]

.filter-property-bubble
  @apply bg-[#{$global-blue-accent}] flex items-center  gap-[#{$menu-viewmode-gap-property-edit}] rounded-[#{$page-properties-br-overlay}] px-1 py-0.5 text-[#{$global-blue-primary}]

.multi-select-button
  @apply flex items-center gap-1 cursor-pointer t-menu-sm text-[#{$menu-viewmode-t-property}] rounded-[#{$menu-br-item}] hover:bg-[#{$menu-bg-item-hover}] hover:text-#{$global-t-default} py-1 pr-1.5 pl-1 -my-1 -mx-1.5

.grip
  @apply text-[#{$global-bg-icon-secondary-alt}] ml-0.75
</style>

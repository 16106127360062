<script setup lang="ts">
import { translate } from "~/utils/helpers"

const { gestureState } = useGestures()
const { selectedFiles } = useSelection()
const dragged = ref<HTMLDivElement>()

const initialX = gestureState.value.mousePosition?.clientX || -1000
const initialY = gestureState.value.mousePosition?.clientY || -1000

function manuallyUpdateDragPosition(event: MouseEvent) {
  dragged.value!.style.transform = translate(event.clientX, event.clientY)
}
function firstDraggedFile() {
  return selectedFiles()[0]
}

onMounted(() => {
  document.body.addEventListener("mousemove", manuallyUpdateDragPosition, true)
})
onUnmounted(() => {
  document.body.removeEventListener("mousemove", manuallyUpdateDragPosition, true)
})
</script>

<template>
  <div
    ref="dragged"
    class="pointer-events-none absolute max-h-16 max-w-16 transform overflow-hidden rounded-sm"
    z="drag-layer"
    :style="{
      transform: translate(initialX, initialY),
    }"
  >
    <img
      :src="firstDraggedFile()?.icon64NativeUri()"
      class="pointer-events-none max-h-16 max-w-16 object-contain"
    >
  </div>
</template>

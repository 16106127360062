<script setup lang="ts">
const { navigationState } = useNavigation()
const { sortedContents, mainViewState } = useMainView()
const { viewModeState, defaultColumnModeWidth } = useViewMode()

const contentsByColumn = computed(() => {
  const contents: InterfaceFile[][] = [[]]
  // Iterate over all sorted contents and place them into columns, starting from root
  let currentFolder = navigationState.value.file?.fileId
  let nextFolder
  while (currentFolder) {
    for (const file of sortedContents.value) {
      if (file.parentId() !== currentFolder)
        continue
      contents[contents.length - 1].push(file)
      if (mainViewState.value.openFolders[file.fileId]) {
        nextFolder = file.fileId
      }
    }
    if (!nextFolder)
      break
    currentFolder = nextFolder
    nextFolder = undefined
    contents.push([])
  }

  return contents
})
</script>

<template>
  <div class="column-view">
    <main-column-single
      v-for="files, index in contentsByColumn"
      :key="index"
      :column-width="viewModeState.current.columnView?.columns[index] || defaultColumnModeWidth"
      :index
      :files
    />
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.column-view
  @apply h-full flex
</style>

<script setup lang="ts">
import iconFolderFilled from "~/assets/icons/folder-filled.svg"

const props = defineProps<{
  file: InterfaceFile
  includeFile?: boolean
}>()
const { navigateToFolder } = useNavigation()

const compressedPath = computed(() => {
  let path: InterfaceFile[] = props.file.path()?.reverse() ?? []
  path = props.includeFile ? [...path, props.file] : path
  if (path.length <= 2)
    return path
  else return [path[0], null, path[path.length - 1]]
})
</script>

<template>
  <div class="h-5.5 flex gap-1.25 overflow-hidden text-sm -mx-1.5">
    <template
      v-for="(item, i) in compressedPath"
      :key="item?.fileId"
    >
      <div
        v-if="i !== 0"
        class="my-0.5 w-0 flex-none rotate-22 transform border-l border-l-border-secondary"
      />
      <div
        v-if="item"
        class="group flex cursor-pointer gap-1.25 overflow-hidden rounded-md px-1 py-0.75"
        hover="bg-accent-neutral"
        @click="item.isFolder() && navigateToFolder(item as InterfaceFolderFile)"
      >
        <icon-folder-filled
          v-if="i === 0"
          class="w-3 flex-none text-text-secondary"
          group-hover="text-$global-violet-primary"
        />
        <div
          v-if="item.isHomeFolder()"
          class="text-ellipses-inline"
        >
          Home
        </div>
        <div
          v-else-if="item.isArchiveFolder()"
          class="text-ellipses-inline"
        >
          Home
        </div>
        <div
          v-else
          class="text-ellipses-inline"
        >
          {{ item.name() }}
        </div>
      </div>
      <div
        v-else
        class="flex items-center text-text-secondary"
      >
        ...
      </div>
    </template>
  </div>
</template>

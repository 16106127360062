<script setup lang="ts">
import { IconAlertTriangleFilled, IconCheck, IconX } from "@tabler/icons-vue"

defineProps<{
  alert: AlertNotification
}>()

const { clearNotificationById } = useNotifications()
</script>

<template>
  <div v-if="alert.type === 'error-alert'" class="alert error group">
    <div class="icon">
      <icon-alert-triangle-filled :size="20" :stroke-width="2" />
    </div>
    <div class="main">
      <div v-if="alert.title" class="title">
        {{ alert.title }}
      </div>
      <div v-if="alert.description" class="description">
        {{ alert.description }}
      </div>
    </div>
    <div
      class="clear"
      @click.stop.prevent="clearNotificationById(alert.id)"
    >
      <icon-x :size="16" :stroke-width="1.5" />
    </div>
  </div>
  <div
    v-else-if="alert.type === 'success-alert'"
    class="alert group success"
  >
    <div class="icon">
      <icon-check :size="20" :stroke-width="2" />
    </div>
    <div class="main">
      <div v-if="alert.title" class="title">
        {{ alert.title }}
      </div>
      <div v-if="alert.description" class="description">
        {{ alert.description }}
      </div>
    </div>
    <div
      class="clear"
      @click.stop.prevent="clearNotificationById(alert.id)"
    >
      <icon-x :size="16" :stroke-width="1.5" />
    </div>
  </div>
  <div v-else>
    Unknown alert type
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.alert
  @apply py-2 px-4 flex gap-2 items-center
  .icon
    @apply w-6 h-6 rounded-md flex-none flex items-center justify-center
  .main
    @apply grow-1 space-y-1
    .title
      @apply text-base font-medium
    .description
      @apply text-sm text-text-secondary
  .clear
    @apply aspect-square items-center flex flex-none hover:bg-accent-strongest px-1 -mx-1 cursor-pointer rounded-full t-[#{$global-t-secondary}]
  &.success
    .icon
      @apply text-$global-blue-primary
  &.error
    @apply bg-[#{$global-red-accent}]
    .icon
      @apply text-[#{$global-red-primary}]
    .title
      @apply
    .description
      @apply
</style>

<script setup lang="ts">
import columnGrip from "~/assets/icons/column-grip.svg"

const globals = useGlobals()
const { y } = useMouse()

/**
 * NOTE: Sidebar dragging to resize is handled by:
 * [useSidebarDragToResize](../../classes/gestures/useSidebarDragToResize.ts)
 */
const id = globals.sidebarDraggerId
</script>

<template>
  <div :id class="divider-container group">
    <div class="divider" />
    <column-grip class="grip" :style="{ top: px(y) }" />
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.divider-container
  @apply absolute h-full w-2 px-0.75 -right-1 z-gesture-overlay-layer hover:cursor-none
.divider
  @apply h-full w-full bg-[#{$page-b-divider}] opacity-0 transition group-hover:opacity-100
.grip
  @apply absolute w-3.5 h-7 -my-3.5 text-[#{$page-b-divider}] -right-0.75 opacity-0 group-hover:opacity-100
</style>

<script setup lang="ts">
import type { InterfaceUser } from "~/classes/users/InterfaceUser"
import abhay from "~/assets/images/beta/abhay.jpg?url"
import samuel from "~/assets/images/beta/samuel.png?url"
import demo from "~/assets/images/logo/logo.webp?url"

defineProps<{ user?: InterfaceUser, size: number }>()

const { sidebarAvatarBubbleTestId } = useTestId()
const { demoUserEmail } = useUser()

// TODO: Remove after beta video shoot
</script>

<template>
  <div
    v-if="user?.email() === demoUserEmail"
    :style="{ width: px(size), height: px(size) }"
    :data-testid="sidebarAvatarBubbleTestId"
    class="avatar-bubble"
  >
    <img :src="demo">
  </div>
  <div
    v-else-if="user?.email() === 'aabhay@gmail.com'"
    :style="{ width: px(size), height: px(size) }"
    :data-testid="sidebarAvatarBubbleTestId"
    class="avatar-bubble"
  >
    <img :src="abhay">
  </div>

  <div
    v-else-if="user?.email() === 'abhay@withpoly.com'"
    :style="{ width: px(size), height: px(size) }"
    :data-testid="sidebarAvatarBubbleTestId"
    class="avatar-bubble"
  >
    <img :src="abhay">
  </div>
  <div
    v-else-if="user?.email() === 'samuel@withpoly.com'"
    :style="{ width: px(size), height: px(size) }"
    :data-testid="sidebarAvatarBubbleTestId"
    class="avatar-bubble"
  >
    <img :src="samuel">
  </div>
  <div
    v-else-if="user?.email() === 'samuelwaynelo@gmail.com'"
    :style="{ width: px(size), height: px(size) }"
    :data-testid="sidebarAvatarBubbleTestId"
    class="avatar-bubble"
  >
    <img :src="samuel">
  </div>
  <div
    v-else
    :style="{ width: px(size), height: px(size) }"
    :data-testid="sidebarAvatarBubbleTestId"
    class="avatar-bubble"
    v-html="gradientAvatar(user?.email(), size)"
  />
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.avatar-bubble
  @apply rounded-full aspect-square overflow-hidden
</style>

<script setup lang="ts">
import iconFolderFilled from "~/assets/icons/folder-filled.svg"

const props = defineProps<{ files: InterfaceFile[] }>()
const { navigateToFolder } = useNavigation()

if (props.files.length < 1)
  throw new Error("Cannot have zero files")

const firstPath = computed(() => props.files[0])
const samePath = computed(() => all(props.files, f => f.parentId() === firstPath.value.parentId()))
const compressedPath = computed(() => {
  const path = firstPath.value.path()?.reverse() ?? []
  if (path.length <= 2)
    return path
  else return [path[0], null, path[path.length - 1]]
})
</script>

<template>
  <div v-if="samePath" class="path-item">
    <template
      v-for="(item, i) in compressedPath"
      :key="item?.fileId"
    >
      <div
        v-if="i !== 0"
        class="my-0.5 w-0 flex-none rotate-22 transform border-l border-l-border-secondary"
      />
      <div
        v-if="item"
        class="group flex cursor-pointer gap-1.25 overflow-hidden rounded-md px-1 py-0.75"
        hover="bg-accent-neutral"
        @click="item.isFolder() && navigateToFolder(item as InterfaceFolderFile)"
      >
        <icon-folder-filled
          v-if="i === 0"
          class="w-3 flex-none text-text-secondary"
          group-hover="text-$global-violet-primary"
        />
        <div class="text-ellipses-inline">
          {{ item.name() }}
        </div>
      </div>
      <div v-else class="flex items-center text-text-secondary">
        ...
      </div>
    </template>
  </div>
  <panel-details-property-multi-placeholder v-else />
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.path-item
  @apply h-5.5 flex gap-1.25 overflow-hidden t-menu-sm -mx-1.5
</style>

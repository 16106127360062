<script setup lang="ts">
const props = defineProps<{
  file: InterfaceFile
}>()
const { viewModeState, previewScale } = useViewMode()
const { isSelected } = useSelection()
const { clipboardState } = useClipboard()

const selected = computed(() => isSelected(props.file.fileId))
const cut = computed(() => clipboardState.value.action === "cut"
  ? clipboardState.value.files[props.file.fileId]
  : undefined,
)

function previewWidth() {
  // const scale = mainViewState.value.viewModePreviewProperties.scale
  const scale = previewScale()
  const width = 2 ** (5 + 5 * scale)
  return width
}
</script>

<template>
  <div
    :data-file-id="file.fileId"
    class="icon-file"
  >
    <div
      class="icon-file-container"
      :class="{ cut }"
      :style="{
        width: px(previewWidth()),
        height: px(previewWidth()),
      }"
    >
      <file-thumbnail
        :file
        shadowed
        :selected
        :container-width="previewWidth()"
        :container-height="previewWidth()"
        class="icon-file-thumbnail"
      />
    </div>
    <div
      class="icon-file-properties"
      :style="{ width: px(previewWidth()) }"
    >
      <div
        v-for="{ propertyId, size, color } in viewModeState.current.show.slice(1)"
        :key="propertyId"
        class="icon-file-property"
      >
        <file-property-view

          :property-id
          :size
          :file
          :color
          :selected
          text-alignment="left"
          multiline
          :max-lines="5"
        />
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.icon-file
  @apply flex-none cursor-pointer p-1 space-y-2
.icon-file-container
  @apply aspect-square flex items-center justify-center
.icon-file-thumbnail
  @apply max-h-full max-w-full rounded-sm
.icon-file-properties
  @apply flex flex-col gap-[#{$page-properties-gap-file}]
.icon-file-property
  @apply w-full flex

// If the file was cut before pasting
.cut
  @apply opacity-25
</style>

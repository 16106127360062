<script setup lang="ts">
defineProps<{ search: InterfaceSearch }>()

const { addSearchesToSidebarLast, removeFromSidebar, isSearchInSidebar } = useSidebar()
</script>

<template>
  <div>
    <menu-section>
      <context-menu-dropdown-item shortcut="↵">
        Re-run
      </context-menu-dropdown-item>
      <menu-separator />
      <context-menu-dropdown-item
        v-if="isSearchInSidebar(search)"
        @click="removeFromSidebar(undefined, [search])"
      >
        Unpin from sidebar
      </context-menu-dropdown-item>
      <context-menu-dropdown-item
        v-else
        @click="addSearchesToSidebarLast([search])"
      >
        Pin to sidebar
      </context-menu-dropdown-item>
    </menu-section>
  </div>
</template>

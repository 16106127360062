import { Node, mergeAttributes } from "@tiptap/core"
import { VueNodeViewRenderer } from "@tiptap/vue-3"
import editableTag from "~/components/File/Property/EditableTag.vue"

const _console = useLogger("EditableTag", theme.colors.violet.hex)

export class EditableTag {
  static TAG = "editableTag"

  static createNode() {
    return Node.create({
      name: EditableTag.TAG,
      group: "block",
      atom: true,
      selectable: true,
      parseHTML() {
        return [{ tag: EditableTag.TAG }]
      },
      renderHTML({ HTMLAttributes }) {
        return [EditableTag.TAG, mergeAttributes(HTMLAttributes), 0]
      },
      addNodeView() {
        return VueNodeViewRenderer(editableTag)
      },
      addAttributes() {
        return {
          value: {
            default: "",
            parseHTML: element => element.getAttribute("value"),
            renderHTML: ({ value }) => ({ value }),
          },
        }
      },
    })
  }
}

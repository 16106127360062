<script setup lang="ts">
import { type Property, PropertySchema } from "~/classes/entity"
import type { InterfaceFile } from "~/classes/file"

const props = defineProps<{
  file: InterfaceFile
  propertyId: Property
  schemaType: PropertySchema.NonNullableStringValue | PropertySchema.NullableStringValue
  multiline?: boolean
  newlines?: boolean
  wrap?: boolean
}>()
const { propertyEditorState, isEditingProperty, stopEditing } = useProperty()
const editable = ref()

const editing = computed(() => isEditingProperty(props.file.fileId, props.propertyId))

const value = computed(() => {
  const value = props.file.property(props.propertyId)?.value
  if (!value)
    return console.error("Cannot determine property type")
  if (value.type === PropertySchema.NonNullableStringValue)
    return value.nonNullableString
  if (value.type === PropertySchema.NullableStringValue)
    return value.nullableString
  console.log(value)
  throw new Error("No way to unpack value for this property")
})

watch(editing, (isEditing) => {
  if (isEditing)
    setTimeout(() => { editable.value.$el.focus() }, 100)
})
</script>

<template>
  <div>
    <interface-content-editable
      v-if="schemaType === PropertySchema.NonNullableStringValue && 'nonNullableString' in propertyEditorState.newValue"
      v-show="editing"
      ref="editable"
      v-model="propertyEditorState.newValue.nonNullableString"
      :schema-type
      tag="div"
      contenteditable="plaintext-only"
      class="no-scrollbar editable multiline bg-transparent"
      :wrap
      :newlines
      spellcheck="false"
      @returned="stopEditing()"
    />
    <interface-content-editable
      v-else-if="schemaType === PropertySchema.NullableStringValue && 'nullableString' in propertyEditorState.newValue"
      v-show="editing"
      ref="editable"
      v-model="propertyEditorState.newValue.nullableString"
      :schema-type
      tag="div"
      contenteditable="plaintext-only"
      class="no-scrollbar editable multiline bg-transparent"
      :wrap
      :newlines
      spellcheck="false"
      @returned="stopEditing()"
    />
    <div
      v-show="!editing"
      class="editable multiline min-line-height-one"
      :class="{ wrap, newlines }"
    >
      {{ value }}
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.multiline
  @apply whitespace-normal overflow-auto text-base max-h-43 break-words
.editable
  @apply cursor-text rounded-md px-2.5 py-1.5 ring-1 ring-[#{$menu-b-input-default}] bg-[#{$menu-bg-input-text-box}] ring-inset focus:outline-none focus:ring-2 focus:ring-$global-blue-primary
.wrap
  @apply break-all\!
.newlines
  @apply whitespace-pre-line\!
</style>

<script setup lang="ts">
defineProps<{
  shortcut?: string
  selected?: boolean // for keyboard navigation purposes
  disabled?: boolean
}>()
const emit = defineEmits(["click"])
const { hideContextMenu } = useContextMenu()
function closeAndEmit() {
  hideContextMenu()
  emit("click")
}
</script>

<template>
  <button class="dropdown-item group" @click="() => !disabled && closeAndEmit()">
    <div class="dropdown-container" :class="{ selected, disabled }">
      <span class="dropdown-text">
        <slot />
      </span>
      <menu-shortcut v-if="shortcut" class="dropdown-shortcut">
        {{ shortcut }}
      </menu-shortcut>
    </div>
  </button>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.dropdown-item
  @apply h-[#{$menu-context-h-item}] w-full text-size-sm t-menu-sm flex

.dropdown-container
  @apply flex flex-grow cursor-pointer items-center rounded-[#{$menu-br-item}] m-[#{$menu-m-hover-outer}] p-[#{$menu-p-hover-inner}] w-full
  &.disabled
    @apply text-[#{$menu-t-item-disabled}] group-hover:cursor-not-allowed
  &:not(.disabled)
    @apply text-[#{$menu-t-item-default}] group-hover:bg-[#{$menu-bg-item-hover}] group-hover:text-[#{$menu-t-item-hovered}]

.dropdown-text
  @apply h-[#{$menu-context-h-item}] overflow-hidden break-words text-ellipsis whitespace-nowrap

.dropdown-shortcut
  @apply ml-auto

.selected
  @apply bg-[#{$menu-bg-default}]
</style>

<script setup lang="ts">
import type { InterfaceSharedFolderInfo } from "~/classes/sharedFolder/InterfaceSharedFolderInfo"

// TODO: go to archive

defineProps<{ sharedFolderInfo: InterfaceSharedFolderInfo }>()
const { leaveSharedFolder } = useSharedFolders()
</script>

<template>
  <div>
    <menu-section>
      <context-menu-dropdown-item shortcut="↵">
        Open
      </context-menu-dropdown-item>
    </menu-section>
    <menu-separator />
    <menu-section>
      <context-menu-dropdown-item>
        Go to archive
      </context-menu-dropdown-item>
    </menu-section>
    <menu-separator />
    <menu-section>
      <context-menu-dropdown-item @click="leaveSharedFolder(sharedFolderInfo.sharedFolderId())">
        <span class="text-$global-red-primary font-bold">Leave</span>
      </context-menu-dropdown-item>
    </menu-section>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
</style>

<script setup lang="ts">
// Helper dropdown during search

const { searchState } = useSearch()
</script>

<template>
  <transition
    appear
    mode="out-in"
    enter-active-class="transition duration-60 ease-out"
    enter-from-class="transform opacity-0"
    enter-to-class="transform opacity-100"
    leave-active-class="transition duration-40"
    leave-from-class="transform opacity-100"
    leave-to-class="transform opacity-0"
  >
    <div class="menu-dropdown-container dropdown">
      <search-helper-suggested-searches v-if="searchState.mode === 'search'" />
      <search-helper-suggested-tags v-else-if="searchState.mode === 'tag'" />
      <search-helper-suggested-path v-else-if="searchState.mode === 'path'" />
      <search-helper-suggested-files v-else-if="searchState.mode === 'file'" />
      <search-helper-suggested-properties v-else-if="searchState.mode === 'property'" />
      <div v-else>
        Unknown state: {{ searchState.mode }}
      </div>
    </div>
  </transition>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.dropdown
  @apply w-[#{$menu-search-w-full}]
  @apply fixed overflow-hidden
</style>

<script setup lang="ts">

</script>

<template>
  <div class="multi">
    ...
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.multi
  @apply rounded-[#{$menu-br-item}] bg-[#{$menu-bg-item-hover}] px-2
</style>

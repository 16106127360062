<script setup lang="ts">
import type { StyleValue } from "vue"

const { sortedContents } = useMainView()
const { viewModeState, previewScale } = useViewMode()
const { isSelected } = useSelection()

const container = ref<HTMLDivElement>()
const { width } = useElementSize(container)
const throttledWidth = refThrottled(width, 1000 / 30)

/**
 * This method lays out the files in a masonry layout style in the spirit of
 * Pinterest, etc.
 * @param nCols Number of columns to generate columns for
 */
function fileColumns(nCols: number) {
  if (!nCols)
    return []
  const columns = Array.from(Array(nCols), _ => ({} as Record<string, InterfaceFile>))
  const heights = Array<number>(nCols).fill(0)
  for (const file of sortedContents.value) {
    let v = 1
    if (file.imageWidth() && file.imageHeight())
      v = file.imageHeight()! / file.imageWidth()!
    const iCol = heights.indexOf(Math.min(...heights))
    heights[iCol] += v
    columns[iCol][file.fileId] = file
  }
  return columns
}
function aspectRatioStyle(file: InterfaceFile): StyleValue {
  const [width, height] = (file.imageWidth() && file.imageHeight())
    ? [file.imageWidth(), file.imageHeight()]
    : [1, 1]
  return { "aspect-ratio": `${width} / ${height}`, "width": "100%" }
}
function previewWidth() {
  // const scale = mainViewState.value.viewModePreviewProperties.scale
  const scale = previewScale()
  const width = 2 ** (5 + 5 * scale)
  return width
}
function previewHeight(file: InterfaceFile) {
  const [width, height] = (file.imageWidth() && file.imageHeight())
    ? [file.imageWidth()!, file.imageHeight()!]
    : [1, 1]
  return previewWidth() * height / width
}
const numColumns = computed(() => {
  return Math.round(throttledWidth.value / previewWidth())
})

function showDetailsOnHoverOnly(file: InterfaceFile) {
  if (isSelected(file.fileId))
    return false
  if (file.isBlank() || file.isFolder())
    return false
  return true
}
</script>

<template>
  <div class="spacer" />
  <div
    ref="container"
    class="flex gap-0.5 p-0.5"
  >
    <div
      v-for="(column, key) in fileColumns(numColumns)"
      :key
      class="w-full flex flex-col gap-0.5"
    >
      <div
        v-for="(file, fileId) in column"
        :key="fileId"
        :data-file-id="fileId"
        class="group relative w-full cursor-pointer"
      >
        <div
          class="feed-file"
          :class="{ selected: isSelected(file.fileId) }"
        />

        <file-thumbnail
          :file
          :selected="isSelected(file.fileId)"
          :container-width="previewWidth()"
          :container-height="previewHeight(file)"
          :style="aspectRatioStyle(file)"
          flat
        />
        <div
          class="feed-file-properties"
          :class="{
            hidden: showDetailsOnHoverOnly(file),
            flex: !showDetailsOnHoverOnly(file),
          }"
        >
          <file-property-view
            v-for="{ propertyId, size, color } in viewModeState.current.show"
            :key="propertyId"
            :property-id
            :size
            :file
            :color
            bg
            text-alignment="left"
            class="feed-file-property"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.spacer
  @apply pointer-events-none w-full
  @apply h-[#{$nav-h-default}] mb-[#{$nav-gap-pills}]

.feed-file
  @apply pointer-events-none absolute z-gesture-layer border-[#{$global-blue-primary}] rounded-[2px] -bottom-0.5 -left-0.5 -right-0.5 -top-0.5 group-hover:border-2
  &.selected
    @apply border-2
.feed-file-properties
  @apply absolute bottom-0 left-0 max-w-full gap-0.5 p-1 overflow-hidden items-center group-hover:flex
.feed-file-property
  @apply flex-none
</style>

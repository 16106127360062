<script setup lang="ts">
import type { ImageRendererGestureState } from "~/classes/gestures/useImageRendererGestures"

const props = defineProps<{
  image: InterfaceImageFile
  gestureLayer: number
}>()
const { imageRendererContainerClass } = useGlobals()
const rendererState = ref<ImageRendererGestureState>({
  current: reactive(props.image),
  isDragging: false,
  isWheeling: false,
  viewX: 0,
  viewY: 0,
  viewScaleExp: 0,
})
const image = ref<HTMLImageElement>()
const container = ref<HTMLDivElement>()
const handler = useImageRendererGestures(container, image, rendererState)
handler.layer = props.gestureLayer

const { mountGesture, unmountGesture } = useGestures()

onMounted(() => mountGesture(handler))
onUnmounted(() => unmountGesture(handler))
</script>

<template>
  <div
    ref="container"
    :class="[imageRendererContainerClass]"
    class="renderer-container"
    :style="{ cursor: rendererState.isDragging ? 'grabbing' : 'grab' }"
  >
    <img
      ref="image"
      src=""
      class="renderer-image"
      draggable="false"
    >
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.renderer-container
  @apply h-full w-full overflow-hidden flex justify-center items-center
.renderer-image
  @apply max-h-full max-w-full object-cover
</style>

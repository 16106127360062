<script setup lang="ts">

</script>

<template>
  <div class="menu" />
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.menu
  @apply h-11 w-21 rounded-br-[16px] bg-[#{$pill-bg-default}] border-b-[#{$pill-b-outline}] border-b border-r-[#{$pill-b-outline}] border-r flex overflow-hidden
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25)
  app-region: no-drag
</style>

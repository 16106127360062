<script setup lang="ts">
import type { Editor } from "@tiptap/vue-3"
import { EditorContent, useEditor } from "@tiptap/vue-3"
import Image from "@tiptap/extension-image"
import Document from "@tiptap/extension-document"
import Text from "@tiptap/extension-text"
import Paragraph from "@tiptap/extension-paragraph"
import Dropcursor from "@tiptap/extension-dropcursor"
import Placeholder from "@tiptap/extension-placeholder"
import folder from "~/assets/icons/folder-filled.svg"
import { FolderRef } from "~/classes/search/FolderRef"
import { ImageRef } from "~/classes/search/ImageRef"
import { TagRef } from "~/classes/search/TagRef"
import IconCrystalBall from "~/assets/icons/crystal-ball-line.svg?component"
import { PathCompletion } from "~/classes/search/PathCompletion"
import { FileCompletion } from "~/classes/search/FileCompletion"
import { TagCompletion } from "~/classes/search/TagCompletion"
import { PropertyCompletion } from "~/classes/search/PropertyCompletion"

const { searchState, onKeyDown, onEditorUpdated, onFileCompletionResponse, onSearchCompletionResponse } = useSearch()
const { navIgnoreMainViewGestures, searchButton } = useGlobals()
const { addInterfaceEventListener, removeInterfaceEventListener } = useEvents()

const editor = useEditor({
  content: searchState.value.intent as any,
  extensions: [
    Document,
    Paragraph,
    Text,
    Placeholder,
    Image.configure({ inline: true }),
    Dropcursor,
    ImageRef,
    FolderRef,
    TagRef.createNode(),
    PathCompletion.createNode(),
    FileCompletion.createNode(),
    TagCompletion.createNode(),
    PropertyCompletion.createNode(),
  ],
  editorProps: {
    handleKeyDown: (_, event) => onKeyDown(editor.value!, event),
  },
  onUpdate: ({ editor }) => onEditorUpdated(editor as Editor),
  // We call the update method on create since we want to immediately
  // request any completions
  onCreate: ({ editor }) => onEditorUpdated(editor as Editor),
})

const focusOnSearchBar = () => {
  editor.value?.commands.focus("end")
}

onMounted(() => {
  // The timeout here should ideally match the transition duration for the
  // search bar
  setTimeout(focusOnSearchBar, 100)
  searchState.value.editor = editor.value
  addInterfaceEventListener("fileFindResponse", onFileCompletionResponse)
  addInterfaceEventListener("searchFindResponse", onSearchCompletionResponse)
})
onUnmounted(() => {
  searchState.value.editor = undefined
  removeInterfaceEventListener("fileFindResponse", onFileCompletionResponse)
  removeInterfaceEventListener("searchFindResponse", onSearchCompletionResponse)
})
</script>

<template>
  <div class="search-container" :class="[searchButton, navIgnoreMainViewGestures]">
    <div v-if="searchState.isLoading" class="h-4.5 w-4.5 flex-none flex items-center justify-center">
      <interface-spinner class="w-3 h-3 mb-0.75" />
    </div>
    <folder v-else-if="searchState.mode === 'path'" class="folder" />
    <icon-crystal-ball v-else class="crystal-ball" />
    <editor-content :editor class="editor-content" />
  </div>
  <div class="search-helper">
    <search-helper-view />
  </div>
</template>

<style lang="sass">
@import '~/assets/styles/generated/variables.sass'

// Everything must be under this class since we can't use scoped
// styles with tiptap
.search-container
  @apply h-full flex items-center px-1 gap-[#{$pill-gap-default}] text-[#{$global-t-default}] relative
  .crystal-ball
    @apply h-4.5 w-4.5 flex-none
  .folder
    @apply h-4 w-4 flex-none text-[#{$global-violet-primary}]
  .editor-content
    @apply w-full resize-none outline-none h-[#{$search-h-fragment-lg}] overflow-y-visible caret-[#{$global-blue-primary}]

  .tiptap
    @apply h-full flex
    &.ProseMirror-focused
      outline: none
    p
      line-height: $search-h-fragment-lg
      &.is-editor-empty:first-child::before
        content: 'Search for anything...'
        float: left
        height: 0
        pointer-events: none
        @apply text-[#{$menu-t-input-placeholder}] font-normal
      .suggestion
        @apply bg-[#{$global-steel-accent}] text-[#{$global-t-secondary}] py-[#{$search-py-fragment}] px-[#{$search-px-fragment}] rounded-[#{$menu-br-default}] t-property-md

  // Needed anymore?
  textarea
    -ms-overflow-style: none
    scrollbar-width: none
  textarea::-webkit-scrollbar
    display: none

.search-helper
  @apply absolute left-[#{$nav-gap-pills}] top-[#{$nav-gap-pills}] mt-[#{$pill-h-default}] pt-[#{$nav-gap-pills}] pointer-events-none
</style>

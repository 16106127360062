<script setup lang="ts">
import { refThrottled, useElementSize } from "@vueuse/core"

const { sortedContents } = useMainView()
const { viewModeState, previewScale } = useViewMode()
const { isSelected } = useSelection()

const container = ref<HTMLDivElement>()
const { width } = useElementSize(container)
const throttledWidth = refThrottled(width, 1000 / 30)

const previewWidth = computed(() => {
  const scale = previewScale()
  const width = 2 ** (5 + 5 * scale)
  return width
})
const numColumns = computed(() => {
  const maxColumnWidth = viewModeState.value.current.detailView.maxColumnWidth
  if (maxColumnWidth < 100) {
    console.warn("Column width invalid or container missing")
    return 0
  }
  return Math.ceil(throttledWidth.value / maxColumnWidth)
})
</script>

<template>
  <div class="spacer" />
  <div
    ref="container"
    class="grid grid-cols-2 gap-0.5 overflow-x-hidden p-0.5 pt-0"
    :style="{ gridTemplateColumns: `repeat(${numColumns}, minmax(0, 1fr))` }"
  >
    <div
      v-for="file in sortedContents"
      :key="file.fileId"
      class="group relative flex cursor-pointer gap-2 p-2"
    >
      <div
        :data-file-id="file.fileId"
        class="aspect-square flex flex-none items-center justify-center"
        :style="{
          width: px(previewWidth),
        }"
      >
        <file-thumbnail
          :file
          shadowed
          :selected="isSelected(file.fileId)"
          :container-width="previewWidth"
          :container-height="previewWidth"
        />
      </div>
      <div
        class="detail-file-properties"
        :data-file-id="file.fileId"
      >
        <div
          v-for="{ propertyId, size, color } in viewModeState.current.show.slice(1)"
          :key="propertyId"
          class="detail-file-property"
        >
          <file-property-view
            :property-id
            :size
            :file
            :color
            :selected="isSelected(file.fileId)"
            text-alignment="left"
            multiline
            :max-lines="5"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.spacer
  @apply pointer-events-none w-full
  @apply h-[#{$nav-h-default}]
.drop-shadow-icon
  filter: drop-shadow(-4px 2px 4px #10101040)
.drop-shadow-blue-hover
  filter: drop-shadow(-4px 2px 4px #10101040)
  &:hover
    @include alphaShadow(var(--poly-blue), 0.75px, drop-shadow(-4px 2px 4px #10101040))
  &.selected
    @include alphaShadow(var(--poly-blue), 2px, drop-shadow(-4px 2px 4px #10101040))

.detail-file-properties
  @apply min-w-0 flex flex-col justify-center gap-[#{$page-properties-gap-file}]
.detail-file-property
  @apply w-full flex
</style>

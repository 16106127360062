<script setup lang="ts">
const { sortedContents } = useMainView()
const { sidebarWaterfallId } = useGlobals()
const { isSelected } = useSelection()

// TODO: scale the thumbnail based on the size of the view
// TODO: make the waterfall more configurable?
</script>

<template>
  <div :id="sidebarWaterfallId" class="waterfall">
    <div
      v-for="file in sortedContents"
      :key="file.fileId"
      class="aspect-square w-full cursor-pointer"
      :data-file-id="file.fileId"
    >
      <div v-if="file.isFolder()" class="aspect-square">
        <img src="~/assets/images/files/folder/poly_folder@256.webp" class="p-2">
      </div>
      <div
        v-else
        class="waterfall-tile"
        :class="{
          selected: isSelected(file.fileId) }"
      >
        <file-thumbnail
          :file
          :container-height="44"
          :container-width="44"
          cropped
        />
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.waterfall
  @apply grid grid-cols-5 flex-none gap-0.5 grid-content-start overflow-auto pl-0.25 h-full p-0.5

.waterfall-tile
  @apply aspect-square
  &.selected
    @apply outline outline-[#{$global-blue-primary}] rounded-[2px] outline-2
</style>

<script setup lang="ts">
const { tauriState, closeWindow, minimizeWindow, maximizeWindow, unmaximizeWindow } = useTauri()
</script>

<template>
  <div class="menu">
    <button
      class=" text-white hover:bg-white/[.06] active:bg-white/[.04]"
      @click="minimizeWindow"
    >
      <nav-window-icons icon="minimizeWin" />
    </button>
    <button
      v-if="tauriState.isWindowMaximized"
      class="text-white hover:bg-white/[.06] active:bg-white/[.04]"
      @click="unmaximizeWindow"
    >
      <nav-window-icons icon="maximizeRestoreWin" />
    </button>
    <button
      v-else
      class="text-white hover:bg-white/[.06] active:bg-white/[.04]"
      @click="maximizeWindow"
    >
      <nav-window-icons icon="maximizeWin" />
    </button>
    <button
      class=" hover:bg-[#c42b1c] hover:text-white active:bg-[#c42b1c]/90 text-white"
      @click="closeWindow"
    >
      <nav-window-icons icon="closeWin" />
    </button>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.menu
  @apply h-11 rounded-bl-[16px] bg-[#{$pill-bg-default}] border-b-[#{$pill-b-outline}] border-b border-l-[#{$pill-b-outline}] border-l flex overflow-hidden
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25)
  app-region: no-drag
  button
    @apply inline-flex items-center justify-center h-full w-[46px]  rounded-none
</style>

<script setup lang="ts">
import { PropertySchema, tierName } from "~/classes/entity"
import type { InterfaceUser } from "~/classes/users/InterfaceUser"

const { sharedFolderState, inviteUserToCurrentSharedFolder } = useSharedFolders()
const sharedFolderInfo = computed(() => sharedFolderState.value.current!.sharedFolderInfo)
const users = computed(() => Object.values(sharedFolderState.value.current!.users))
const userDetails = computed(() => {
  const properties = sharedFolderInfo.value.home().sharedWithProperties()
  const details: Record<string, [FileProperty, InterfaceUser | undefined]> = {}
  for (const property of properties) {
    const userId = property?.sharedWithUserId
    if (!userId)
      throw new Error("Invalid property for sharedWith")
    details[userId] = [property, sharedFolderState.value.current!.users[userId]]
  }
  return details
})

const role = ref<string>("Owner")

// TODO: update this after beta
function betaHandle(user: InterfaceUser) {
  if (user.email() === "aabhay@gmail.com")
    return "Abhay Agarwal"
  if (user.email() === "abhay@withpoly.com")
    return "Abhay Agarwal"
  if (user.email() === "samuel@withpoly.com")
    return "Samuel Lo"
  if (user.email() === "samuelwaynelo@gmail.com")
    return "Samuel Lo"
  return "Unknown"
}
</script>

<template>
  <div class="menu-dropdown-container dropdown">
    <menu-section>
      <menu-header label="Access" />
      <div class="menu-sharing-access" @click="sharedFolderState.current!.isPublicDemo = false">
        <div
          class="inner"
          :class="{
            selected: !sharedFolderState.current?.isPublicDemo,
          }"
        >
          <div class="icon">
            <icon-action
              v-if="!sharedFolderState.current?.isPublicDemo"
              size="xs"
              type="check"
            />
          </div>
          <div>Private</div>
          <icon-property
            size="xs"
            type="private"
            class="ml-auto"
          />
          <div>Invite only</div>
        </div>
      </div>
      <div class="menu-sharing-access" @click="sharedFolderState.current!.isPublicDemo = true">
        <div
          class="inner"
          :class="{
            selected: sharedFolderState.current?.isPublicDemo,
          }"
        >
          <div class="icon">
            <icon-action
              v-if="sharedFolderState.current?.isPublicDemo"
              size="xs"
              type="check"
            />
          </div>
          <div class="">
            Public
          </div>
          <icon-property
            size="xs"
            type="public"
            class="ml-auto"
          />
          <div>Live</div>
        </div>
      </div>
    </menu-section>
    <menu-separator />
    <menu-section>
      <menu-header label="Permissions" />
      <div class="menu-permissions">
        <interface-input
          v-model="sharedFolderState.current!.invite.email"
          class="menu-permissions-input"
          placeholder="Invite by handle or email..."
          @keydown.enter="inviteUserToCurrentSharedFolder()"
        />
        <interface-button
          class="menu-permissions-button"
          :loading="sharedFolderState.current!.invite.isLoading"
          @click="inviteUserToCurrentSharedFolder()"
        >
          Invite
        </interface-button>
      </div>
      <div
        v-for="[property, user], userId in userDetails"
        :key="userId"
        class="menu-user"
      >
        <user-avatar :user :size="28" />
        <div class="menu-user-details">
          <div class="menu-user-name">
            {{ user ? betaHandle(user) : "Loading..." }}
          </div>
          <div class="menu-user-email">
            {{ user ? user.email() : "Loading..." }}
          </div>
        </div>
        <div v-if="property.value.type === PropertySchema.RoleUuidValue" class="menu-user-role">
          <menu-selection
            v-model="property.value.roleUuid"
            :loading="property.pending"
            :options="[
              'Owner',
              'Admin',
              'Editor',
              'Viewer',
              'Remove',
            ]"
            :label="k => tierName(k)"
          />
        </div>
      </div>
    </menu-section>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.dropdown
  @apply w-[#{$menu-share-w-default}] border

.menu-sharing-access
  @apply h-[#{$menu-share-h-item}] t-menu-base
  .inner
    @apply -m-1 p-1 rounded-1 flex gap-[#{$menu-share-gap-item}] items-center cursor-pointer
    &:hover,&.selected
      @apply bg-[#{$menu-bg-item-hover}]
  .icon
    @apply h-[#{$global-w-icon-xs}] w-[#{$global-w-icon-xs}]

.menu-permissions
  @apply flex gap-[#{$menu-gap-items}]
.menu-permissions-input
  @apply flex-grow-1 w-full
.menu-permissions-button
  @apply px-3 rounded-lg bg-$global-blue-primary text-base font-medium

.menu-user
  @apply flex items-center gap-[#{$menu-gap-items}]
.menu-user-avatar
  @apply rounded-full bg-red-400 w-[#{$menu-share-h-avatar}] h-[#{$menu-share-h-avatar}]
.menu-user-details
  .menu-user-name
    @apply t-menu-base
  .menu-user-email
    @apply t-menu-xs text-[#{$menu-share-t-permissions-secondary}]
.menu-user-role
  @apply ml-auto
</style>

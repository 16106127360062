import { watchThrottled } from "@vueuse/core"
import { Gesture } from "./BaseGesture"

export interface ImageRendererGestureState {
  current: InterfaceImageFile
  isDragging: boolean
  isWheeling: boolean
  viewX: number
  viewY: number
  viewScaleExp: number
}

export default function (
  container: Ref<HTMLDivElement | undefined>,
  image: Ref<HTMLImageElement | undefined>,
  rendererState: Ref<ImageRendererGestureState>,
) {
  const SCROLL_SCALE_FACTOR = 0.0025
  const { isPrimaryClick, gestureState, gestureLayers } = useGestures()
  const { transcodeImageToPngBlobUri } = useTransferManager()

  class ImageRendererGestures extends Gesture {
    layer = gestureLayers.overlay

    #dragStartX = 0
    #dragStartY = 0
    #viewStartX = 0
    #viewStartY = 0

    reset(): void {
      rendererState.value.isDragging = false
      rendererState.value.isWheeling = false
      this.#dragStartX = 0
      this.#dragStartY = 0
      this.#viewStartX = 0
      this.#viewStartY = 0
    }

    async onMounted() {
      this.displayImage()
      watch(() => rendererState.value.current, this.displayImage)
      watchThrottled(
        this.cssTransform,
        () => image.value!.style.transform = this.cssTransform(),
        { throttle: 1000 / 30 },
      )
    }

    async onMouseDown(mouse: MouseEvent) {
      if (!isPrimaryClick(mouse))
        return this.reset()
      if (!getFirstMatchingParent(
        mouse.target as HTMLDivElement,
        t => t === container.value,
      ))
        return this.reset()

      this.console.log("Started preview drag")
      this.capture()
      rendererState.value.isDragging = true
      this.#dragStartX = mouse.pageX
      this.#dragStartY = mouse.pageY
      this.#viewStartX = rendererState.value.viewX
      this.#viewStartY = rendererState.value.viewY
    }

    async onMouseMove(mouse: MouseEvent) {
      if (!rendererState.value.isDragging)
        return this.reset()

      this.capture()
      const distX = mouse.pageX - this.#dragStartX
      const distY = mouse.pageY - this.#dragStartY
      rendererState.value.viewX = this.#viewStartX + distX / Math.exp(rendererState.value.viewScaleExp)
      rendererState.value.viewY = this.#viewStartY + distY / Math.exp(rendererState.value.viewScaleExp)
    }

    async onMouseUp(_mouse: MouseEvent) {
      this.reset()
    }

    async onMouseEnter(_mouse: MouseEvent) {
      if (!gestureState.value.mouseButtonsPressed.has(0) || !rendererState.value.isDragging)
        return this.reset()
    }

    async onWheel(wheel: WheelEvent) {
      if (!getFirstMatchingParent(
        wheel.target as HTMLDivElement,
        t => t === container.value,
      ))
        return this.reset()
      const zoomY = wheel.deltaY * SCROLL_SCALE_FACTOR
      rendererState.value.viewScaleExp -= zoomY // zoom inverse to scale
    }

    async displayImage() {
      const file = rendererState.value.current
      const bounds = container.value?.getBoundingClientRect()
      if (!file || !file.isImage() || !image.value || !bounds)
        return console.warn("No current file or not an image, likely due to loading")

      // Set view scale to container size
      // TODO: better algo for this
      // const widthExp = Math.log(bounds.width / file.imageWidth()!)
      // const heightExp = Math.log(bounds.height / file.imageHeight()!)
      // rendererState.value.viewScaleExp = Math.min(widthExp, heightExp)

      let imageSrc = file.clientUri()
      if (!imageSrc)
        return console.error("Object has no uri")
      if (!["image/jpeg", "image/png", "image/webp"].includes(file.mimeType()!))
        imageSrc = await transcodeImageToPngBlobUri(file)
      image.value.src = imageSrc
      image.value!.style.transform = this.cssTransform()
    }

    cssTransform() {
      return `scale(${Math.exp(rendererState.value.viewScaleExp)}) translate3d(${rendererState.value.viewX}px, ${rendererState.value.viewY}px, 0px)`
    }
  }
  return new ImageRendererGestures()
}

<script setup lang="ts">

</script>

<template>
  <nav-pill-view class="sign-in-pill">
    Sign In
  </nav-pill-view>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.sign-in-pill
  @apply bg-[#{$global-blue-primary}] p-3
</style>

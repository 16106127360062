<script setup lang="ts">
import { IconArrowLeft, IconArrowRight } from "@tabler/icons-vue"

const router = useRouter()
const { navHistoryBackTestId, navHistoryForwardTestId } = useTestId()
</script>

<template>
  <nav-pill-view>
    <nav-pill-button
      class="pill-history-item"
      :data-testid="navHistoryBackTestId"
      @click="router.back()"
    >
      <icon-arrow-left :size="20" :stroke-width="1.5" />
    </nav-pill-button>
    <nav-pill-button
      class="pill-history-item"
      :data-testid="navHistoryForwardTestId"
      @click="router.forward()"
    >
      <icon-arrow-right :size="20" :stroke-width="1.5" />
    </nav-pill-button>
  </nav-pill-view>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'

.pill-history-item
  @apply text-[#{$pill-t-default}]
  &:hover
    @apply text-[#{$pill-t-hovered}]
</style>
